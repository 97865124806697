import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import moment from "moment";
import ChatSideBar from "../Components/ChatSideBar";
import ModalPopup from "../../../components/ModalPopup/ModalPopup";
import NavGroupIcon from "../../../components/Icons/NavIcons/NavGroupIcon";
import DropDownItem from "../../../components/DropDownItem/DropDownItem";
import MuteIcon from "../../../components/Icons/GeneralIcons/MuteIcon";
import NotificationIcon from "../../../components/Icons/GeneralIcons/NotificationIcon";
import StarIcon from "../../../components/Icons/GeneralIcons/StarIcon";
import DeleteIcon from "../../../components/Icons/GeneralIcons/DeleteIcon";
import ChatMemberCell from "../Components/HelperComponets/ChatMemberCell";
import {
  getMuteChatIdListForUser,
  updatestarchat,
  clearchatredu,
  readMessageCountChange,
} from "../../../Action";
import authService from "../../../Services/auth.service";
import LogoutIcon from "../../../components/Icons/GeneralIcons/LogoutIcon";
import CrossIcon from "../../../components/Icons/GeneralIcons/CrossIcon";
import SearchField from "../../../components/InputField/SearchField";
import EmptyState from "../../../components/EmptyState/EmptyState";
import newsocket from "../../../components/SocketConnect";
import CreateNewGroupModal from "./CreateNewGroupModal";
import Typography from "../../../components/Typography/Typography";
import Spinners from "../../../components/Spinners/Spinners";
import MuteChatModal from "../Components/MuteChatModal";
import { checkMuteStatusForList } from "../CommonFunctions/CheckMuteChat";

const ChatList = ({
  addMemberSelected,
  alreadyExistingMembers,
  removeAddMemberSelected,
  setEditorClear,
  setOnChangeChatList,
  onChangeChatList,
}) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { userid, type } = useParams();
  const [contextmenu, setcontextmenu] = useState({ x: 0, y: 0 });
  const [contextMenuId, setcontextMenuId] = useState(0);
  const [contextMenuChatType, setcontextMenuChatType] = useState("");
  const [showContextMenuName, setContextMenuName] = useState("");
  const [quote_btn, setquotebtn] = useState(false);
  const [quote_btn_start, setquote_btn_start] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [filter, setFilter] = useState("");
  const [allUserList, setAllUserList] = useState([]);
  const [isLoader, setIsLoader] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [isOpenGroupModal, setIsOpenGroupModal] = useState(false);
  const [openModelLeave, setOpenModelLeave] = useState(false);
  const [leaveGroupChannelLoader, setGroupChannelLoader] = useState(false);
  const [leaveClearHistoryLoader, setClearHistoryLoader] = useState(false);
  const [isModalOpenClearChat, setIsModalOpenClearChat] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isResetModalData, setIsResetModalData] = useState(false)
  const [hasMoreChannels, setHasMoreChannels] = useState(true);
  const [hasMoreMessages, setHasMoreMessages] = useState(true);

  const myId = localStorage.getItem("userid");
  const dropdownRef = useRef(null);

  const chatListData = useSelector((state) => {
    return state.ReduAllChatList;
  });

  const chatListDataForChannel = useSelector((state) => {
    return state.ReduAllChatListChannel;
  });

  const allMessages = useSelector((state) => {
    return state.ReduChat;
  });

  const starchatuser = useSelector((state) => {
    return state.ReduStarChat ?? [];
  });

  const totalChatList = useSelector((state) => state?.ReduChatListLoadMore);

  const muteNotificationList = useSelector(
    (state) => state?.muteNotificationReducer
  );

  // console.log("chatListDataForChannel", chatListDataForChannel);
  // console.log("chatListData", chatListData);
  // Starred chats for channels
  const starChatListChannel = chatListDataForChannel.filter(
    (chat) =>
      chat.isStar === true && // Check if chat is starred
      chat.firstname &&
      chat.firstname.toLowerCase().includes(searchInput.toLowerCase())
  );

  // Starred chats for messages
  const starChatListMessages = chatListData.filter(
    (chat) =>
      chat.isStar === true && // Check if chat is starred
      chat.firstname &&
      chat.firstname.toLowerCase().includes(searchInput.toLowerCase())
  );

  // Unstarred chats for channels
  const unstarChatListChannel = chatListDataForChannel.filter(
    (chat) =>
      (chat.isStar === false || chat.isStar === undefined) && // Check if chat is unstarred
      chat.firstname &&
      chat.firstname.toLowerCase().includes(searchInput.toLowerCase())
  );

  // Unstarred chats for messages
  const unstarChatListMessages = chatListData.filter(
    (chat) =>
      (chat.isStar === false || chat.isStar === undefined) && // Check if chat is unstarred
      chat.firstname &&
      chat.firstname.toLowerCase().includes(searchInput.toLowerCase())
  );

  // console.log("unstarChatListMessages", unstarChatListMessages);

  const countChatChannel = chatListDataForChannel.reduce((sum, item) => {
    if (
      item.type === "chatChannel" &&
      !muteNotificationList?.notificationBadgeNotDisplay?.includes(item.id)
    ) {
      let count = item.count ?? 0;
      return sum + count;
    }
    return sum;
  }, 0);

  const countChatMessage = chatListData.reduce((sum, item) => {
    if (
      item.type !== "chatChannel" &&
      !muteNotificationList?.notificationBadgeNotDisplay?.includes(item.id)
    ) {
      let count = item.count ?? 0;
      return sum + count;
    }
    return sum;
  }, 0);

  const getMuteNotificationList = async () => {
    dispatch(getMuteChatIdListForUser());
  };

  const chatAllUsers = useSelector((state) => state.ReduAllUsersList);

  useEffect(() => {
    setAllUserList(chatAllUsers);
    setIsLoader(false);
  }, [chatAllUsers]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setquote_btn_start(false);
        setquotebtn(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyPress);
    };


  }, []);

  // Event listener for keyboard keys
  const handleKeyPress = (event) => {
    if (event.keyCode === 27) {
      console.log(isModalOpenClearChat, "isModalOpenClearChat");
      setOpenModel(false);
      setIsOpenGroupModal(false);
      setIsModalOpenClearChat(false)
      setOpenModelLeave(false)
      setIsResetModalData(true);
    }
  };

  const handleStarUser = () => { };

  const handleClick = () => { };

  const handleChatListClick = () => { };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        event.target.closest(".quote-model") === null &&
        contextmenu &&
        contextmenu.y !== null &&
        contextmenu.x !== null
      ) {
        setcontextmenu(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [contextmenu, handleStarUser, setcontextmenu]);

  const openChatListModel = () => {
    setOpenModel(!openModel);
    setFilter("");
  };

  const CheckMuteStatus = (chatType) => {
    let muteStatus = false;
    if (chatType === "userChatGroup" || chatType === "user") {
      const userMessages = chatListData?.filter(
        (muteData) => muteData.id === contextMenuId
      );
      muteStatus = checkMuteStatusForList(
        userMessages[0]?.isMuted,
        userMessages[0]?.muteUntil
      );
    } else if (chatType === "chatChannel") {
      const userChannels = chatListDataForChannel?.filter(
        (muteData) => muteData.id === contextMenuId
      );
      muteStatus = checkMuteStatusForList(
        userChannels[0]?.isMuted,
        userChannels[0]?.muteUntil
      );
    }
    return muteStatus;
  };
  
  const generateContextMenuOptions = (chatType) => {
    const isMuted = CheckMuteStatus(chatType);

    // Check if the chat is starred in the chatListData
    let chatData;
    if (chatType === "chatChannel") {
      chatData = chatListDataForChannel.find(
        (chat) => chat.id === contextMenuId
      );
    } else {
      chatData = chatListData.find((chat) => chat.id === contextMenuId);
    }
    const isPinned = chatData?.isStar || false;
    const isSameUser = contextMenuId === userid; 

    const commonOptions = [
      {
        icon: isMuted ? (
          <MuteIcon
            height={24}
            iconColor="var(--color-icon-neutral-strong)"
            width={24}
          />
        ) : (
          <NotificationIcon
            height={24}
            iconColor="var(--color-icon-neutral-strong)"
            width={24}
          />
        ),
        name: isMuted ? "Unmute notification" : "Mute notification",
        isError: false,
      },
      {
        icon: <StarIcon isFilled={isPinned} />,
        name: isPinned ? "Remove from starred" : "Move to starred",
        isError: false,
      },
      {
        icon: <DeleteIcon height={24} iconColor="#DB1D26" width={24} />,
        name: "Delete conversation",
        isError: true,
      },
      isSameUser && {
        icon: <CrossIcon height={24} iconColor="#000" width={24} />,
        name: "Close chat",
        isError: false,
      },
    ].filter(Boolean);

    switch (chatType) {
      case "chatChannel":
        return [
          ...commonOptions,
          {
            icon: (
              <DeleteIcon
                height={24}
                width={24}
                iconColor="var(--color-icon-danger-strong)"
              />
            ),
            name: "LeaverMessage",
            isError: true,
          },
        ];
      case "userChatGroup":
        return [
          ...commonOptions,
          {
            icon: (
              <LogoutIcon
                height={24}
                width={24}
                iconColor="var(--color-icon-danger-strong)"
              />
            ),
            name: "Leave group",
            isError: true,
          },
        ];
      case "user":
        return commonOptions;
      default:
        return [];
    }
  };

  const options = generateContextMenuOptions(contextMenuChatType);

  const handleonClickMuteNotification = () => {
    const isMuted = CheckMuteStatus(type)

    if (isMuted) {
      newsocket.emit("muteChat", {
        chatId: contextMenuId,
        userId: localStorage.getItem("userid"),
        muteUntil: null,
      });
    } else {
      setIsModalOpen(true);
    }
  };

  const onSelect = (selectedOption) => {
    (selectedOption.name === "Unmute notification" ||
      selectedOption.name === "Mute notification") &&
      handleonClickMuteNotification();
    (selectedOption.name === "Remove from starred" ||
      selectedOption.name === "Move to starred") &&
      onClickPinUpin();
    selectedOption.name === "Delete conversation" && handleClearHistory();
    selectedOption.name === "Close chat" && handleCloseChat();
    selectedOption.name === "Leave channel" && handleLeaveGroup();
    selectedOption.name === "Leave group" && handleLeaveGroup();
  };

  const handleLeaveChannelFun = () => {
    if (contextMenuChatType === "userChatGroup") {
      newsocket.emit("leave-group", {
        groupid: contextMenuId,
        authUserId: localStorage.getItem("userid"),
        time_stamp: moment.utc().format("YYYY-MM-DDTHH:mm:ss.SSSSSS[Z]"),
      });
      newsocket.emit("leaveRoom", { roomId: contextMenuId });
      dispatch({
        type: "DELETECHATFROMLIST",
        payload: contextMenuId,
      });
      dispatch(clearchatredu(contextMenuId));
      setOpenModelLeave(false);
      history("/chats/me/me");
    } else if (contextMenuChatType === "chatChannel") {
      newsocket.emit("leave-channel", {
        channelId: contextMenuId,
        authUserId: localStorage.getItem("userid"),
        time_stamp: moment.utc().format("YYYY-MM-DDTHH:mm:ss.SSSSSS[Z]"),
      });
      newsocket.emit("leaveRoom", { roomId: contextMenuId });
      dispatch({
        type: "DELETECHATFROMLIST",
        payload: contextMenuId,
      });
      dispatch(clearchatredu(contextMenuId));
      setOpenModelLeave(false);
      history("/chats/me/me");
    }
  };

  // handle Leave Group modalbox
  const handleLeaveGroup = () => {
    setOpenModelLeave(true);
  };

  const onClickPinUpin = async () => {
    // dispatch(updatestarchat(contextMenuId));
    newsocket.emit("starChat", {
      chatId: contextMenuId,
      type: contextMenuChatType,
      userId: localStorage.getItem("userid"),
    });
    // authService.starchat(contextMenuId);
  };

  const handleCloseChat = () => {
    history("/chats/me/me");
  };

  const handleChatList = (chatData) => {
    const firstName = chatData?.fullName || "";
    const sanitizedFirstName = firstName.replace("&", " ");
    setOpenModel(false);
    history(
      "/chats/" +
      chatData.id +
      "/" +
      "user" +
      "?user_name=" +
      sanitizedFirstName +
      "&" +
      "user_image=" +
      (chatData.profileimg && chatData.profileimg.startsWith("https")
        ? chatData.profileimg
        : process.env.REACT_APP_AWS_IMG_VID_PATH + chatData.profileImg || "")
    );
  };

  const handleClearChat = () => {
    setClearHistoryLoader(true);
    dispatch(clearchatredu(contextMenuId));
    dispatch({ type: "DELETECHATFROMLIST", payload: contextMenuId });
    authService
      .clearchat({
        id: contextMenuId,
        type: contextMenuChatType,
        deleteConversation: true,
      })
      .then((res) => {
        if (res.data.status) {
          // newsocket.emit("leaveRoom", {
          //   roomId:
          //     contextMenuChatType !== "user" &&
          //     contextMenuChatType !== "airtable-syncs"
          //       ? contextMenuId
          //       : localStorage.getItem("userid") + "-" + contextMenuId,
          // });
          // newsocket.emit("newChatList", { id: localStorage.getItem("userid") });
          newsocket.emit("getLastMessage", {
            roomId: contextMenuId,
            userId: localStorage.getItem("userid"),
          });
          setIsModalOpenClearChat(false);
          if (contextMenuId === userid) {
            history("/chats/me/me");
          }
        }
      })
      .catch((e) => { });
    setClearHistoryLoader(false);
  };

  const handleChatListClickFunc = (chatData, chatCount) => {
    const userId = localStorage.getItem("userid");
    dispatch(readMessageCountChange(chatData.id));
    setEditorClear(true);
    if (chatCount > 0) {
      if (allMessages && allMessages[chatData.id]) {
        let allUnreadMessages = allMessages[chatData.id].filter((msgData) => {
          if (
            msgData.group_member &&
            msgData.group_member.findIndex(
              (item) =>
                item.id === localStorage.getItem("userid") &&
                item.readmsg === false
            ) !== -1
          ) {
            return msgData;
          }
        });
        if (allUnreadMessages && allUnreadMessages.length) {
          // Dispatch the last unread message ID only if there are unread messages
          dispatch({
            type: "ASSIGNUNREADMESSAGE",
            payload: allUnreadMessages[allUnreadMessages.length - 1]._id,
          });
        }
        setTimeout(() => {
          dispatch({ type: "RESETUNREADMESSAGEINDEX", payload: "" });
        }, 5000);
      }
      // if (chatData.type === "user") {
      //   newsocket.emit("readMessage", {
      //     read_flag: true,
      //     recipient: localStorage.getItem("userid"),
      //     sender: chatData.id,
      //     type: "user",
      //   });
      // } else if (chatData.type === "userChatGroup") {
      //   newsocket.emit("readMessage", {
      //     read_flag: true,
      //     recipient: chatData.id,
      //     sender: localStorage.getItem("userid"),
      //     type: "userChatGroup",
      //   });
      // } else if (chatData.type === "chatchannel") {
      //   newsocket.emit("readMessage", {
      //     read_flag: true,
      //     recipient: chatData.id,
      //     sender: localStorage.getItem("userid"),
      //     type: "chatchannel",
      //   });
      // }
      const readMessagePayload = {
        read_flag: true,
        recipient: chatData.type === "user" ? userId : chatData.id,
        sender: chatData.type === "user" ? chatData.id : userId,
        type: chatData.type,
      };
      newsocket.emit("readMessage", readMessagePayload);
      // else if (
      //   chatData &&
      //   chatData.sender &&
      //   chatData.sender.id === localStorage.getItem("useiId")
      // ) {
      //   dispatch({ type: "ASSIGNUNREADMESSAGE", payload: "" });
      // }
    } else {
      dispatch({ type: "RESETUNREADMESSAGEINDEX", payload: "" });
    }
    setOnChangeChatList(!onChangeChatList);
    history(
      "/chats/" +
      chatData.id +
      "/" +
      chatData.type +
      "?user_name=" +
      chatData.firstname.replace("&", " ") +
      "&" +
      "user_image=" +
      `${chatData.type !== "user" && chatData.type !== "userChatGroup"
        ? process.env.REACT_APP_AWS_IMG_VID_PATH + chatData.image
        : chatData.image
      }`
    );
  };

  const activityMessageDisplay = (message) => {
    switch (message.activity.type) {
      // -Please not Remove this commented code this code is future use when client revert to channel Activity
      // case "createdChannel":
      //   return `Admin created ${message.activity.newGroupName} channel.`;
      case "created":
        return `${message.activity.adminId ? message.activity.adminId.display_name : ""
          } created ${message.activity.newGroupName} group.`;
      case "left":
        return `${message.activity.userId && message.activity.userId[0].display_name
          ? message.activity.userId[0].display_name
          : ""
          } leave the group.`;
      case "added":
      // -Please not Remove this commented code this code is future use when client revert to channel Activity
      case "addChannelMembers":
        return `${message.activity.userId
          .map((user) => {
            return user.display_name
              ? user.display_name
              : "";
          })
          .join(",")}  joined the ${message.activity.type === "added" ? "group" : "channel"
          }.`;
      case "removed":
        return `${message.activity.adminId ? message.activity.adminId.display_name : ""
          }  removed ${message.activity.userId
            .map((user) => {
              return user.display_name
                ? user.display_name
                : "";
            })
            .join(",")} from group.`;
      // -Please not Remove this commented code this code is future use when client revert to channel Activity
      // case "removedChannelMembers":
      //   return `Admin removed ${message.activity.userId
      //     .map((user) => {
      //       return user.display_name
      //         ? user.display_name.substring(0, user.display_name.indexOf(" "))
      //         : "";
      //     })
      //     .join(",")} from channel.`;
      case "editedImage":
        return `${message.activity.adminId ? message.activity.adminId.display_name : ""
          } changed group image.`;
      // -Please not Remove this commented code this code is future use when client revert to channel Activity
      // case "editedChannelIcon":
      //   return `Admin changes channel image.`;
      case "editedName":
        return `${message.activity.adminId ? message.activity.adminId.display_name : ""
          } edited group name from ${message.activity.previousGroupName} to ${message.activity.newGroupName
          }.`;
      // -Please not Remove this commented code this code is future use when client revert to channel Activity
      // case "editedChannelName":
      //   return `Admin edited channel name from ${message.activity.previousGroupName} to ${message.activity.newGroupName}.`;
      default:
        return "";
    }
  };

  const handleSearchBar = (e) => {
    const searchTerm = e.target.value;
    setSearchInput(searchTerm);
  };

  const handleClear = () => {
    setSearchInput("");
  };

  const handleOnClickCreateGroup = () => {
    setIsOpenGroupModal(true);
    setOpenModel(false);
  };

  // handler onClick close create group modal
  const handleonClickClose = () => {
    setIsOpenGroupModal(false);
    setOpenModel(false);
  };

  const handleClose = () => {
    setOpenModelLeave(false);
  };

  // open modalbox for clear chat history
  const handleClearHistory = () => {
    setIsModalOpenClearChat(true);
  };

  // close modalbox for clear chat history
  const handleCloseClearHistory = () => {
    setIsModalOpenClearChat(false);
  };
  // get More chat List For Channel/message
  const handleLoadMoreChatList = (type) => {
    const total =
      type === "channel"
        ? totalChatList.totalChannels
        : totalChatList.totalMessages;
    const totalLength =
      type === "channel" ? chatListDataForChannel.length : chatListData.length;
    if (totalLength < total) {
      newsocket.emit("newChatList", {
        id: localStorage.getItem("userid"),
        type: type === "channel" ? "chatChannel" : "messages",
        skip: totalLength,
      });
    } else {
      if (type === "channel") {
        setHasMoreChannels(false);
      } else {
        setHasMoreMessages(false);
      }
    }
  };
  return (
    <>
      <div ref={dropdownRef} className="chatlist-container">
        <ChatSideBar
          hasAccordions={true}
          hasFloatingButton={true}
          handleClick={handleClick}
          handleChatListClick={handleChatListClick}
          accordionLable="Starred"
          accordionLable1="MDS channels"
          isCollapsed={false}
          isCollapsed1={false}
          contextMenuId={contextMenuId}
          setcontextMenuId={setcontextMenuId}
          setContextMenuName={setContextMenuName}
          quote_btn_start={quote_btn_start}
          setquote_btn_start={setquote_btn_start}
          quote_btn={quote_btn}
          setquotebtn={setquotebtn}
          contextMenuChatType={contextMenuChatType}
          setcontextMenuChatType={setcontextMenuChatType}
          contextmenu={contextmenu}
          setcontextmenu={setcontextmenu}
          handleStarUser={handleStarUser}
          openChatListModel={openChatListModel}
          openModel={openModel}
          muteNotificationList={muteNotificationList}
          handleChatListClickFunc={handleChatListClickFunc}
          chatList={chatListData}
          starchatuser={starchatuser}
          starChatListChannel={starChatListChannel}
          starChatListMessages={starChatListMessages}
          unstarChatListChannel={unstarChatListChannel}
          unstarChatListMessages={unstarChatListMessages}
          activityMessageDisplay={activityMessageDisplay}
          handleSearchBar={handleSearchBar}
          searchInput={searchInput}
          handleClear={handleClear}
          countChatMessage={countChatMessage}
          countChatChannel={countChatChannel}
          handleLoadMoreChatList={handleLoadMoreChatList}
          hasMoreChannels={hasMoreChannels}
          setHasMoreChannels={setHasMoreChannels}
          hasMoreMessages={hasMoreMessages}
          setHasMoreMessages={setHasMoreMessages}
        />

        {/* {quote_btn && contextmenu && ( */}
        <div
          style={{
            position: "fixed",
            top: contextmenu?.y,
            left: contextmenu?.x,
            zIndex: 10,
            visibility: quote_btn ? "visible" : "hidden",
            opacity: quote_btn ? "1" : "0",
          }}
        >
          <DropDownItem
            size="small"
            options={options}
            isIcon={true}
            onSelect={onSelect}
          />
        </div>
        {/* )} */}

        {/*  New chat Model box */}
        <ModalPopup
          onClose={openChatListModel}
          isFooter={false}
          isHeader={true}
          modelSize="480"
          isOpen={openModel}
          className="new-chat-modelbox"
          headingLable="New Chat"
        >
          <div className="new-chat-container">
            <div className="new-chat-container-search">
              <SearchField
                className="text-field-search"
                type="text"
                placeholder="Search for Member"
                value={filter}
                handleChange={(e) => setFilter(e.target.value)}
                isRounded
                handleClear={(e) => setFilter("")}
              />
            </div>
            <div className="new-chat-container-list">
              <div
                className="new-chat-container-list-in"
                onClick={handleOnClickCreateGroup}
              >
                <NavGroupIcon height={24} iconColor="#0B42CF" width={24} />
                <h5>New group</h5>
              </div>
              {/* <div className="new-chat-container-list-in">
                <ChannelIcon height={24} iconColor="#0B42CF" width={24} />
                <h5>New channel</h5>
              </div> */}
            </div>
          </div>

          <hr className="cstm-cgc-line" />
          <div className="chat-list-container scrollbar align-items-center">
            {!isLoader && allUserList.length > 0 ? (
              allUserList
                .filter((user) =>
                  user.fullName.toLowerCase().startsWith(filter.toLowerCase())
                )
                .concat(
                  allUserList.filter(
                    (user) =>
                      !user.fullName
                        .toLowerCase()
                        .startsWith(filter.toLowerCase()) &&
                      user.fullName.toLowerCase().includes(filter.toLowerCase())
                  )
                ).length === 0 ? (
                <div className="new-chat-emptystate">
                  <EmptyState
                    hasTitle={false}
                    subTitle="No search results"
                    isClear={false}
                  />
                </div>
              ) : (
                <div className="chat-list-inner">
                  {allUserList
                    .filter((user) =>
                      user.fullName
                        .toLowerCase()
                        .startsWith(filter.toLowerCase())
                    )
                    .concat(
                      allUserList.filter(
                        (user) =>
                          !user.fullName
                            .toLowerCase()
                            .startsWith(filter.toLowerCase()) &&
                          user.fullName
                            .toLowerCase()
                            .includes(filter.toLowerCase())
                      )
                    )
                    .map((chat, index) => {
                      const listDate = chat.userTimeStamp
                        ? moment(
                          moment.utc(chat.userTimeStamp).toDate()
                        ).format("MMMM D, YYYY")
                        : chat.createdAt && chat.createdAt.length
                          ? moment(chat.createdAt).format("MMMM D, YYYY")
                          : "";
                      const listTime = chat.userTimeStamp
                        ? moment(
                          moment.utc(chat.userTimeStamp).toDate()
                        ).format("hh:mm A")
                        : chat.createdAt && chat.createdAt.length
                          ? moment(chat.createdAt).format("hh:mm A")
                          : "";
                      const sendTime =
                        listDate &&
                          listDate.length &&
                          listDate === moment().format("MMMM D, YYYY")
                          ? listTime
                          : listDate &&
                            listDate.length &&
                            moment().diff(listDate, "days") === 1
                            ? "Yesterday"
                            : listDate && moment().diff(listDate, "days") < 7
                              ? moment(listDate).format("dddd")
                              : listDate && moment().diff(listDate, "months") === 1
                                ? moment(listDate).format("D MMM")
                                : listDate && listDate.length > 0
                                  ? moment(listDate).format("DD/MM/YY")
                                  : listTime;

                      return (
                        <ChatMemberCell
                          staredLi={false}
                          handleChatList={handleChatList}
                          contextmenu={contextmenu}
                          contextMenuId={contextMenuId}
                          setcontextMenuId={setcontextMenuId}
                          quote_btn_start={quote_btn_start}
                          setquote_btn_start={setquote_btn_start}
                          quote_btn={quote_btn}
                          setquotebtn={setquotebtn}
                          setcontextmenu={setcontextmenu}
                          setcontextMenuChatType={setcontextMenuChatType}
                          handleStarUser={handleStarUser}
                          key={chat.id}
                          chat={chat}
                          hasCounter={chat?.count}
                          hasSender={chat?.senderId?._id === myId}
                          hasText={
                            chat && chat.message && chat.message.length
                              ? true
                              : false
                          }
                          messageCount={chat?.unreadMsg}
                          messageType={
                            chat?.messageType
                              ? chat?.messageType.includes("text")
                                ? "text"
                                : chat?.messageType.split(",").pop()
                              : ""
                          }
                          name={chat?.fullName ? chat?.fullName : "Unknown"}
                          type={
                            chat.type === "chatChannel" ||
                              chat.type === "userChatGroup"
                              ? "Group"
                              : "Personal"
                          }
                          time={sendTime}
                          hasMentions={chat?.isMention}
                          src={
                            chat?.profileImg &&
                              chat?.profileImg.split("?")[0].split("/")[
                                chat?.profileImg.split("?")[0].split("/").length -
                                1
                              ].length &&
                              chat.profileImg.startsWith("https")
                              ? chat?.profileImg
                              : chat?.profileImg &&
                                chat?.profileImg.split("?")[0].split("/")[
                                  chat?.profileImg.split("?")[0].split("/")
                                    .length - 1
                                ].length
                                ? process.env.REACT_APP_AWS_IMG_VID_PATH +
                                chat?.profileImg
                                : ""
                          }
                          badgeStatus={
                            chat.type === "chatChannel" ||
                              chat.type === "userChatGroup"
                              ? "offline"
                              : chat?.onlineStatus
                                ? "online"
                                : "offline"
                          }
                          hasStatus={true}
                        />
                      );
                    })}
                </div>
              )
            ) : (
              <div className="chat-sidebar-loading">
                <Spinners size="32" type="brand" />
              </div>
            )}
          </div>
        </ModalPopup>

        {/* Leave Channel Modal box */}
        <ModalPopup
          onClose={handleClose}
          isFooter={true}
          isHeader={false}
          modelSize="648"
          isOpen={openModelLeave}
          buttonText1="Leave"
          buttonText="Cancel"
          btn2variant="danger"
          handleClick2ndButton={handleLeaveChannelFun}
          handleClick={handleClose}
          disabled={leaveGroupChannelLoader}
          hasLoading={leaveGroupChannelLoader}
          spinnersType="white"
          disabled1stbtn={leaveGroupChannelLoader}
        >
          <div className="channel-group-wrapper">
            <Typography variant="h3">
              Leave
              {type === "userChatGroup"
                ? " group"
                : type === "chatChannel"
                  ? " channel"
                  : ""}
            </Typography>
            <Typography variant="p">
              Are you sure you want to leave <b>{showContextMenuName}</b>
              &nbsp;
              {type === "userChatGroup"
                ? "group"
                : type === "chatChannel"
                  ? "channel"
                  : ""}
              ?
            </Typography>
          </div>
        </ModalPopup>

        {/* clear history Modal box */}
        <ModalPopup
          onClose={handleCloseClearHistory}
          isFooter={true}
          isHeader={false}
          modelSize="648"
          isOpen={isModalOpenClearChat}
          buttonText1="Clear"
          buttonText="Cancel"
          btn2variant="danger"
          handleClick2ndButton={handleClearChat}
          handleClick={handleCloseClearHistory}
          disabled={leaveClearHistoryLoader}
          hasLoading={leaveClearHistoryLoader}
          spinnersType="white"
          disabled1stbtn={leaveClearHistoryLoader}
        >
          <div className="channel-group-wrapper">
            <Typography variant="h3">Clear history</Typography>
            <Typography variant="p">
              Are you sure you want to clear the chat history in&nbsp;
              <b>{showContextMenuName}</b>?
            </Typography>
          </div>
        </ModalPopup>

        <CreateNewGroupModal
          removeAddMemberSelected={removeAddMemberSelected}
          alreadyExistingMembers={alreadyExistingMembers}
          addMemberSelected={addMemberSelected}
          isOpenGroupModal={isOpenGroupModal || addMemberSelected}
          isCloseGroupModal={handleonClickClose}
          openChatListModel={openChatListModel}
          setIsOpenGroupModal={setIsOpenGroupModal}
          isResetModalData={isResetModalData}
          setIsResetModalData={setIsResetModalData}
        />

        {/* Mute chat Modal */}
        {isModalOpen && (
          <MuteChatModal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            userid={contextMenuId}
          />
        )}
      </div>
    </>
  );
};
export default ChatList;
