import React, { useEffect, useState, useRef, useCallback } from "react";
import { useDropzone } from "react-dropzone";

import axios from "axios";
import authAdminHeader from "../../../Services/auth.admin.header";

import authService from "../../../Services/auth.service";
import Loader from "../../../Images/loader.gif";
import IcnMMenu from "../../../Images/icn_m_view_cards.svg";
import IcnMRow from "../../../Images/icn_m_view_table.svg";
import IcnMCalendar from "../../../Images/icn_m_calendar.svg";
import uploadIcon from "../../../Images/icn_m_upload.svg";
import VideoIconWhite from "../../../Images/video-icon-white.svg";
import SearchNoResults from "../../../Images/search_no_results.svg";
import PreviewImage from "../../../Images/WF-Image-Placeholder.svg";
import MediaDetailsPopup from "./MediaDetailsPopup";
import AlertComponent from "../../../Hooks/AlertComponent";
import { useDispatch } from "react-redux";
import { InView } from "react-intersection-observer";

const MediaListing = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  // const API_URL = "http://localhost:3002/api/";
  const wrapperRef = useRef(null);
  const [pagination, setPagination] = useState(true);
  const [loader, setLoader] = useState(false);
  const [mediaData, setMediaData] = useState([]);
  const [filteredMediaData, setFilteredMediaData] = useState([]);
  const [responseMessage, setResponseMessage] = useState("");
  const [noDataFound, setDataFound] = useState(false);
  const [activeTab, setActiveTab] = useState("all");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedMediaCategory, setSelectedMediaCategory] = useState([]);
  const [isDragOver, setIsDragOver] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(50);
  const [searchInput, setSearchInput] = useState("");
  const [selectedSortOption, setSelectedSortOption] = useState("Newest"); // Default sort option
  const [activeTabOptions, setActiveTabOptions] = useState("all");
  const [showDetailsPopup, setShowDetailsPopup] = useState(false);
  const [currentImageId, setCurrentImageId] = useState("");
  const [showSortingOption, setSortingOption] = useState(false);
  const [showMediaDisplaySection, setMediaDisplaySection] = useState(true);
  const [showPartialUploadImageArray, setPartialUploadImageArray] =
    useState(false);
  const [showCategoryOption, setCategoryOption] = useState(false);
  const [showCategoryOptionDisplay, setCategoryOptionDisplay] =
    useState("All categories");
  const [showUploadedFiles, setUploadedFiles] = useState([]);
  const [showUploadMedia, setUploadMedia] = useState(false);

  const [uploadedImages, setUploadedImages] = useState([]);
  const [uploadProgressNew, setUploadProgressNew] = useState({});
  const [loading, setLoading] = useState(false);

  const sortOptions = [
    { label: "Newest", value: "Newest" },
    { label: "Oldest", value: "Oldest" },
    { label: "Date Created", value: "Date Created" },
  ];

  const tabOptions = [
    { label: "All", value: "all" },
    { label: "Images", value: "image" },
    { label: "Videos", value: "video" },
  ];

  useEffect(() => {
    setLoader(true);
    getMediaDataAPI(1);
  }, [
    activeTab,
    selectedCategory,
    searchInput,
    selectedSortOption,
    activeTabOptions,
  ]);

  useEffect(() => {
    getCategoryAPI();
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      onOutsideClick();
    }
  };

  const onOutsideClick = () => {
    setSortingOption(false);
    setCategoryOption(false);
  };

  async function getMediaDataAPI(pageNumber) {
    try {
      const queryParams = `filter=${activeTabOptions}&category=${selectedCategory}&sortBy=${selectedSortOption}&page=${pageNumber}&pageSize=${pageSize}`;

      const response = await authService.getMediaDetails(queryParams);

      if (response.data.status) {
        setUploadedFiles([]);
        if (pageNumber === 1) {
          filterMediaData(response.data.data.items);
          const ReturnData = response.data.data.items.filter((data) => {
            return !data.isProgress && !data.isError;
          });
          setMediaData(ReturnData);
        } else {
          filterMediaData([...filteredMediaData, ...response.data.data.items]);
          const ReturnData = response.data.data.items.filter((data) => {
            return !data.isProgress && !data.isError;
          });
          setMediaData([...mediaData, ...ReturnData]);
        }

        setDataFound(false);
        setPagination(true);
        setLoading(false);
        setPartialUploadImageArray(false);
        if (response.data.data.items.length > 0) {
          const inProgressData = response.data.data.items.filter((data) => {
            return data.isProgress;
          });
          getMediaProgressFun(inProgressData);
        }
        setLoader(false);
      } else if (response.data.data.length === 0) {
        setLoader(false);
        setPagination(false);
        setLoading(false);
        setPartialUploadImageArray(false);
        setDataFound(true);
      }
    } catch (error) {
      console.error("Error fetching media data:", error);
    }
  }

  const filterMediaData = (data) => {
    if (activeTab === "all") {
      let filteredData = data;
      if (searchInput.trim() !== "") {
        const searchTerm = searchInput.toLowerCase();
        filteredData = filteredData.filter((item) =>
          item.title.toLowerCase().includes(searchTerm)
        );
      }
      setFilteredMediaData(filteredData);
    } else if (activeTab === "trash") {
      const filteredTrashData = data.filter((item) => item.isTrashed);
      setFilteredMediaData(filteredTrashData);
    }
  };

  async function getMediaProgressFun(idsData) {
    try {
      const queryParams = {
        ids: idsData.map((data) => {
          return data._id;
        }),
      };
      const response = await authService.getMediaProgress(queryParams);
      const inProgressData = response.data.data.filter((data) => {
        return data.isProgress;
      });

      const inProgressCompletedData = response.data.data.filter((data) => {
        return !data.isProgress;
      });
      if (inProgressCompletedData.length > 0) {
        getMediaDataAPI(1);
      }
      if (inProgressData.length > 0) {
        getMediaProgressFun(inProgressData);
      } else if (response.data.data.length === 0) {
        setResponseMessage("");
      }
    } catch (error) {
      console.error("Error fetching media data:", error);
    }
  }

  async function getCategoryAPI() {
    try {
      const response = await authService.getCategoryMediaDetails();
      if (response.data.status) {
        setLoader(false);
        setSelectedMediaCategory(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching media data:", error);
    }
  }

  const fetchMoreData = (pageNumber) => {
    setLoading(true);
    getMediaDataAPI(pageNumber);
  };

  const handleImageClick = (mediaId, e) => {
    setUploadMedia(false);
    setShowDetailsPopup(true);
    document.body.classList.add("modal-open");
    setCurrentImageId(mediaId);
  };

  const onClickTabOption = (option) => {
    setUploadMedia(false);
    setActiveTabOptions(option);
    setCurrentPage(1);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setCurrentPage(1);
  };

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  const onClickSortingSelection = (data) => {
    setUploadMedia(false);
    setSelectedSortOption(data);
    setSortingOption(!showSortingOption);
    setCurrentPage(1);
  };

  const onClickCategorySelection = (category, categoryName) => {
    setUploadMedia(false);
    setCategoryOption(!showCategoryOption);
    if (category !== "All categories") {
      setSelectedCategory(category);
      setCategoryOptionDisplay(categoryName);
      setCurrentPage(1);
    } else {
      setCategoryOptionDisplay(category);
      setSelectedCategory([]);
      setCurrentPage(1);
    }
  };

  const onDragEnter = () => {
    setMediaDisplaySection(false);
    setIsDragOver(true);
  };

  const onDragLeave = () => {
    setMediaDisplaySection(true);
    setIsDragOver(false);
  };

  const { getRootProps, getInputProps } = useDropzone({
    multiple: true,
    maxFilesize: 7096,
    onDragEnter: () => onDragEnter(),
    onDragLeave: () => onDragLeave(),
    onDrop: (acceptedFiles) => onDrop(acceptedFiles),
  });

  const handleSortingClick = () => {
    setSortingOption(!showSortingOption);
  };
  const handleCategoryClick = () => {
    setCategoryOption(!showCategoryOption);
  };

  const handleChoosePopupClick = () => {
    setUploadMedia(!showUploadMedia);
  };

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const MAX_ALLOWED_SIZE = 2 * 1024 * 1024 * 1024;

      const isSizeExceeded = acceptedFiles.some(
        (file) => file.size > MAX_ALLOWED_SIZE
      );

      if (isSizeExceeded) {
        setUploadMedia(false);
        setPartialUploadImageArray(true);
        setMediaDisplaySection(true);
        setResponseMessage(
          "One or more files exceed the maximum allowed size (2GB)."
        );
        setTimeout(() => {
          setResponseMessage("");
        }, 2000);
        return;
      }

      if (acceptedFiles.length > 20) {
        setUploadMedia(false);
        setPartialUploadImageArray(true);
        setMediaDisplaySection(true);
        setResponseMessage("Please upload only 20 images or video");
        setTimeout(() => {
          setResponseMessage("");
        }, 2000);
        return;
      }

      // Create FormData for sending files
      const formData = new FormData();
      const uploadedImagesTempArray = [];

      acceptedFiles.forEach((file) => {
        formData.append("files", file);

        if (file.type.startsWith("image/")) {
          const reader = new FileReader();
          reader.onload = async () => {
            const preview = reader.result;
            uploadedImagesTempArray.push({
              name: file.name.replace(/\s/g, "_"),
              preview: preview,
              file: file,
            });
          };

          reader.readAsDataURL(file);
        } else {
          uploadedImagesTempArray.push({
            name: file.name.replace(/\s/g, "_"),
            file: file,
          });
        }
      });
      setMediaDisplaySection(true);
      setPartialUploadImageArray(true);
      setUploadedImages(uploadedImagesTempArray);
      setIsDragOver(false);
      setUploadMedia(false);

      try {
        // Send images to the backend using your API endpoint
        const response = await axios.post(API_URL + `media/upload`, formData, {
          headers: {
            ...authAdminHeader(),
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            // Calculate progress percentage
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );

            // Update the progress for each file
            acceptedFiles.forEach((file) => {
              setUploadProgressNew((prevProgress) => {
                return {
                  ...prevProgress,
                  [file.name.replace(/\s/g, "_")]: percentCompleted,
                };
              });
            });
          },
        });
        // Handle successful upload
        getMediaDataAPI(1);
        setCurrentPage(1);
        setMediaDisplaySection(true);
        getMediaProgressFun(response.data.data);
        setResponseMessage("Media uploaded successfully");
        setTimeout(() => {
          setResponseMessage("");
        }, 2000);
      } catch (error) {
        // Handle error
        console.error("Error uploading images:", error);
        setResponseMessage(error.message);
        setUploadMedia(false);
        setTimeout(() => {
          setResponseMessage("");
        }, 2000);
      }
    },
    [getMediaDataAPI]
  );

  const handleImageRetry = async (retryImageData) => {
    try {
      if (retryImageData !== undefined) {
        setPartialUploadImageArray(true);

        // Implement logic to retry uploading the image
        const formData = new FormData();
        formData.append("files", retryImageData.file);

        const response = await axios.post(API_URL + `media/upload`, formData, {
          headers: authAdminHeader(),
          onUploadProgress: (progressEvent) => {
            // Calculate progress percentage
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );

            // Update the progress for the retry image
            setUploadProgressNew((prevProgress) => {
              const newProgress = { ...prevProgress };
              delete newProgress[retryImageData.name];
              return {
                ...newProgress,
                [retryImageData.name]: Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                ),
              };
            });
          },
        });

        // Handle successful upload
        getMediaDataAPI(1);
        getMediaProgressFun(response.data.data);
        setResponseMessage("Media uploaded successfully");
      }
    } catch (error) {
      // Handle error
      console.error("Error retrying image upload:", error);
    } finally {
    }
  };

  return (
    <>
      <div className="users-info-content">
        <div className="users-info-content-main">
          {responseMessage && (
            <div className="alert-for-media-processing">{responseMessage}</div>
          )}
          <div className="admin-content-header">
            <h2>Media</h2>
            <div>
              <button
                className="upload-media-btn"
                onClick={handleChoosePopupClick}
              >
                Upload media
              </button>
            </div>
          </div>

          <div className="upload-media-tabs">
            <button
              className={` ${activeTab === "all" ? "active" : ""}`}
              onClick={() => handleTabChange("all")}
            >
              All Media
            </button>
            {/* <button
              className={` ${activeTab === "trash" ? "active" : ""}`}
              onClick={() => handleTabChange("trash")}
            >
              Trash
            </button> */}
          </div>
          {activeTab === "all" && (
            <>
              <div className="upload-media-fillers">
                <div className="upload-media-filler-main">
                  <div className="upload-media-filler-main-left">
                    {/* <div className="filter-list-grid">
                      <button
                        className="filter-list-grid-active"
                        onClick={() => setDisplayMode("list")}
                      >
                        <img src={IcnMMenu} />
                      </button>
                      <button
                        className=""
                        onClick={() => setDisplayMode("grid")}
                      >
                        <img src={IcnMRow} />
                      </button>
                    </div> */}
                    <div className="upload-media-filler-search">
                      <input
                        className="upload-media-filler-search-input"
                        type="text"
                        placeholder="Search..."
                        value={searchInput}
                        onChange={handleSearchChange}
                      />
                      {/* <img
                        src={IcnMCalendar}
                        className="upload-media-filler-search-icon"
                      /> */}
                    </div>

                    <div className="upload-media-filler-dropdown">
                      <div className="upload-media-dropdown">
                        <button
                          className={`upload-media-label ${
                            showCategoryOption && "dropdown-up-arrow"
                          }`}
                          onClick={handleCategoryClick}
                        >
                          {showCategoryOptionDisplay}
                        </button>
                        {showCategoryOption && (
                          <ul
                            className={`upload-media-dropdown-list`}
                            ref={wrapperRef}
                          >
                            <li
                              onClick={() =>
                                onClickCategorySelection("All categories")
                              }
                            >
                              All
                            </li>
                            {selectedMediaCategory.length > 0 &&
                              selectedMediaCategory.map((data) => (
                                <li
                                  key={data._id}
                                  onClick={() =>
                                    onClickCategorySelection(
                                      data._id,
                                      data.name
                                    )
                                  }
                                >
                                  {data.name}
                                </li>
                              ))}
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="upload-media-filler-main-right">
                    <div className="upload-media-dropdown">
                      <button
                        className={`upload-media-label ${
                          showSortingOption && "dropdown-up-arrow"
                        }`}
                        onClick={handleSortingClick}
                      >
                        <span className="sort-by-label">Sort by</span>
                        {selectedSortOption}
                      </button>
                      {showSortingOption && (
                        <ul
                          ref={wrapperRef}
                          className="upload-media-dropdown-list"
                          // style={{ display: "none" }}
                        >
                          {sortOptions.map((option) => (
                            <li
                              key={option.value}
                              onClick={() =>
                                onClickSortingSelection(option.value)
                              }
                            >
                              {option.label}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
                <div className="upload-media-chips-main">
                  {tabOptions.map((typeData) => (
                    <div
                      class={`upload-media-chips ${
                        typeData.value === activeTabOptions ? "tab-active" : ""
                      }`}
                      onClick={() => onClickTabOption(typeData.value)}
                    >
                      {typeData.label}
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
          {loader && currentPage === 1 ? (
            <div className="upload-media-data-loading">
              <img src={Loader} alt="Loading..." />
            </div>
          ) : (
            <>
              {showUploadMedia && (
                // (!showMediaDisplaySection && showPartialUploadImageArray.length !== 0) ?
                <div className="upload-media-drop">
                  <div
                    className={`file-uploader-medium ${
                      isDragOver ? "drag-over" : ""
                    }`}
                    {...getRootProps()}
                  >
                    <div className={`file-uploader-medium-body`}>
                      <input {...getInputProps()} />
                      <div className="file-uploader-medium-body-icon">
                        <img src={uploadIcon} />
                      </div>
                      <div className="file-uploader-medium-body-title-text">
                        <h5>Drag and drop your files here</h5>
                        <h6>
                          Acceptable file format is JPG, JPEG, PNG, WEBP, MP4.
                        </h6>
                      </div>
                    </div>
                    <div className="file-uploader-medium-button">
                      <button>Upload</button>
                    </div>
                  </div>
                </div>
              )}
              {showPartialUploadImageArray && uploadedImages.length === 0 && (
                <div className="upload-media-drop">
                  <div
                    className={`file-uploader-medium ${
                      isDragOver ? "drag-over" : ""
                    }`}
                  >
                    <div className={`file-uploader-medium-body`}>
                      <div className="file-uploader-medium-body-icon">
                        <img src={uploadIcon} />
                      </div>
                      <div className="file-uploader-medium-body-title-text">
                        <h6>Processing...</h6>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {showMediaDisplaySection && (
                <div className="media-images-videos">
                  {showPartialUploadImageArray &&
                    uploadedImages.length > 0 &&
                    uploadedImages.map((image) => (
                      <div
                        className="media-images-videos-column"
                        key={image.name}
                      >
                        {image.preview ? (
                          <img
                            src={image.preview}
                            alt=""
                            className="media-upload-images"
                          />
                        ) : (
                          <img
                            src={PreviewImage}
                            alt=""
                            className="media-upload-images"
                          />
                        )}
                        <div className="media-upload-images-loader">
                          <div className="media-upload-images-loader-in">
                            <span
                              className="media-upload-images-loader-fill"
                              style={{
                                width: uploadProgressNew[image.name] + "%",
                              }}
                            ></span>
                          </div>
                        </div>
                      </div>
                    ))}
                  {/* Inside the mapping of filteredMediaData */}
                  {filteredMediaData.length > 0
                    ? filteredMediaData.map((media, index) => {
                        if (index === filteredMediaData.length - 1) {
                          return (
                            <InView
                              onChange={(inView, entry) => {
                                if (inView && pagination && !loading) {
                                  fetchMoreData(currentPage + 1);
                                  setCurrentPage(currentPage + 1);
                                }
                              }}
                              className="media-images-videos-column"
                              key={media._id}
                            >
                              {media.type === "image" ? (
                                media.isProgress ? (
                                  media.isError ? (
                                    <>
                                      <span
                                        className="compression-loader-img"
                                        onClick={() =>
                                          handleImageRetry(
                                            uploadedImages.find(
                                              (uploadedImage) =>
                                                uploadedImage.name ===
                                                media.title
                                            )
                                          )
                                        }
                                      >
                                        <i
                                          class="fa fa-repeat media-reupload-img"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                      <img
                                        src={
                                          uploadedImages.find(
                                            (uploadedImage) =>
                                              uploadedImage.name === media.title
                                          )?.preview
                                        }
                                        alt={`Preview ${
                                          media.title || `Media ${index}`
                                        }`}
                                        className="media-upload-images"
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <span className="compression-loader-img">
                                        <img src={Loader} alt="Loading..." />
                                      </span>
                                      <img
                                        src={
                                          uploadedImages.find(
                                            (uploadedImage) =>
                                              uploadedImage.name === media.title
                                          )?.preview
                                        }
                                        alt={`Preview ${
                                          media.title || `Media ${index}`
                                        }`}
                                        className="media-upload-images"
                                        // data-toggle="modal"
                                        // data-target="#mediaModel"
                                        // onClick={(e) => handleImageClick(media._id, e)}
                                      />
                                    </>
                                  )
                                ) : (
                                  <img
                                    src={media.urls[0]?.url}
                                    alt={media.title || `Media ${index}`}
                                    className="media-upload-images"
                                    // data-toggle="modal"
                                    // data-target="#mediaModel"
                                    onClick={(e) =>
                                      handleImageClick(media._id, e)
                                    }
                                  />
                                )
                              ) : media.isProgress ? (
                                media.isError ? (
                                  <span
                                    className="compression-loader-img"
                                    onClick={() =>
                                      handleImageRetry(
                                        uploadedImages.find(
                                          (uploadedImage) =>
                                            uploadedImage.name === media.title
                                        )
                                      )
                                    }
                                  >
                                    <i
                                      class="fa fa-repeat media-reupload-img"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                ) : (
                                  <>
                                    <span className="compression-loader-img">
                                      <img src={Loader} alt="Loading..." />
                                    </span>
                                    <img
                                      src={media.videoThumbnailUrl}
                                      alt={`Preview ${
                                        media.title || `Media ${index}`
                                      }`}
                                      className="media-upload-images"
                                    />
                                  </>
                                )
                              ) : (
                                <>
                                  {/* <video className="media-upload-videos" controls>
                                <source src={media.urls[0]?.url} type="video/mp4" />
                                Your browser does not support the video tag.
                              </video> */}
                                  <img
                                    src={media.videoThumbnailUrl}
                                    alt={`Preview ${
                                      media.title || `Media ${index}`
                                    }`}
                                    className="media-upload-images"
                                  />
                                  <div
                                    className="media-upload-videos-loader"
                                    onClick={(e) =>
                                      handleImageClick(media._id, e)
                                    }
                                  >
                                    <img
                                      src={VideoIconWhite}
                                      alt="Video Icon"
                                    />
                                  </div>
                                </>
                              )}
                            </InView>
                          );
                        } else
                          return (
                            <div
                              className="media-images-videos-column"
                              key={media._id}
                            >
                              {media.type === "image" ? (
                                media.isProgress ? (
                                  media.isError ? (
                                    <>
                                      <span
                                        className="compression-loader-img"
                                        onClick={() =>
                                          handleImageRetry(
                                            uploadedImages.find(
                                              (uploadedImage) =>
                                                uploadedImage.name ===
                                                media.title
                                            )
                                          )
                                        }
                                      >
                                        <i
                                          class="fa fa-repeat media-reupload-img"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                      <img
                                        src={
                                          uploadedImages.find(
                                            (uploadedImage) =>
                                              uploadedImage.name === media.title
                                          )?.preview
                                        }
                                        alt={`Preview ${
                                          media.title || `Media ${index}`
                                        }`}
                                        className="media-upload-images"
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <span className="compression-loader-img">
                                        <img src={Loader} alt="Loading..." />
                                      </span>
                                      <img
                                        src={
                                          uploadedImages.find(
                                            (uploadedImage) =>
                                              uploadedImage.name === media.title
                                          )?.preview
                                        }
                                        alt={`Preview ${
                                          media.title || `Media ${index}`
                                        }`}
                                        className="media-upload-images"
                                        // data-toggle="modal"
                                        // data-target="#mediaModel"
                                        // onClick={(e) => handleImageClick(media._id, e)}
                                      />
                                    </>
                                  )
                                ) : (
                                  <img
                                    src={media.urls[0]?.url}
                                    alt={media.title || `Media ${index}`}
                                    className="media-upload-images"
                                    onClick={(e) =>
                                      handleImageClick(media._id, e)
                                    }
                                  />
                                )
                              ) : media.isProgress ? (
                                media.isError ? (
                                  <span
                                    className="compression-loader-img"
                                    onClick={() =>
                                      handleImageRetry(
                                        uploadedImages.find(
                                          (uploadedImage) =>
                                            uploadedImage.name === media.title
                                        )
                                      )
                                    }
                                  >
                                    <i
                                      class="fa fa-repeat media-reupload-img"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                ) : (
                                  <>
                                    <span className="compression-loader-img">
                                      <img src={Loader} alt="Loading..." />
                                    </span>
                                    <img
                                      src={media.videoThumbnailUrl}
                                      alt={`Preview ${
                                        media.title || `Media ${index}`
                                      }`}
                                      className="media-upload-images"
                                    />
                                  </>
                                )
                              ) : (
                                <>
                                  {/* <video className="media-upload-videos" controls>
                            <source src={media.urls[0]?.url} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video> */}
                                  <img
                                    src={media.videoThumbnailUrl}
                                    alt={`Preview ${
                                      media.title || `Media ${index}`
                                    }`}
                                    className="media-upload-images"
                                  />
                                  <div
                                    className="media-upload-videos-loader"
                                    onClick={(e) =>
                                      handleImageClick(media._id, e)
                                    }
                                  >
                                    <img
                                      src={VideoIconWhite}
                                      alt="Video Icon"
                                    />
                                  </div>
                                </>
                              )}
                            </div>
                          );
                      })
                    : ""}
                  {loading && (
                    <>
                      <div className="media-images-videos-column">
                        <div className="media-images-videos-colum-loading skeleton-box"></div>
                      </div>
                      <div className="media-images-videos-column">
                        <div className="media-images-videos-colum-loading skeleton-box"></div>
                      </div>
                      <div className="media-images-videos-column">
                        <div className="media-images-videos-colum-loading skeleton-box"></div>
                      </div>
                      <div className="media-images-videos-column">
                        <div className="media-images-videos-colum-loading skeleton-box"></div>
                      </div>
                      <div className="media-images-videos-column">
                        <div className="media-images-videos-colum-loading skeleton-box"></div>
                      </div>
                      <div className="media-images-videos-column">
                        <div className="media-images-videos-colum-loading skeleton-box"></div>
                      </div>
                      <div className="media-images-videos-column">
                        <div className="media-images-videos-colum-loading skeleton-box"></div>
                      </div>
                      <div className="media-images-videos-column">
                        <div className="media-images-videos-colum-loading skeleton-box"></div>
                      </div>
                      <div className="media-images-videos-column">
                        <div className="media-images-videos-colum-loading skeleton-box"></div>
                      </div>
                      <div className="media-images-videos-column">
                        <div className="media-images-videos-colum-loading skeleton-box"></div>
                      </div>
                    </>
                  )}
                  {noDataFound && filteredMediaData.length === 0 && (
                    <div className="empty-state">
                      <div className="empty-state-icon">
                        <img src={SearchNoResults} />
                      </div>
                      <div className="empty-state-info">
                        <h4 className="empty-state-info-title">
                          Oops! No results this time
                        </h4>
                        <p className="empty-state-info-p">
                          Please adjust your searching Media and give it another
                          go!
                        </p>
                      </div>
                      {/* <button className="empty-state-buttun">
                      Clear all Media
                    </button> */}
                    </div>
                  )}
                </div>
              )}
              {filteredMediaData.length === 0 &&
                uploadedImages.length !== 0 && (
                  <div className="upload-media-drop">
                    <div
                      className={`file-uploader-medium ${
                        isDragOver ? "drag-over" : ""
                      }`}
                      {...getRootProps()}
                    >
                      <div className={`file-uploader-medium-body`}>
                        <input {...getInputProps()} />
                        <div className="file-uploader-medium-body-icon">
                          <img src={uploadIcon} />
                        </div>
                        <div className="file-uploader-medium-body-title-text">
                          <h5>Drag and drop your files here</h5>
                          <h6>Acceptable file format is JPG, JPEG, PNG.</h6>
                        </div>
                      </div>
                      <div className="file-uploader-medium-button">
                        <button>Upload</button>
                      </div>
                    </div>
                  </div>
                )}
            </>
          )}
        </div>
      </div>

      <AlertComponent />
      {showDetailsPopup && (
        <MediaDetailsPopup
          allMedia={filteredMediaData}
          categoryDetails={selectedMediaCategory}
          currentImageId={currentImageId}
          AllMediasData={mediaData}
          getMediaDataAPI={getMediaDataAPI}
          setShowDetailsPopup={setShowDetailsPopup}
        />
      )}
    </>
  );
};

export default MediaListing;
