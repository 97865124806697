export const checkMuteStatusForList = (isMuted, muteUntil) =>  {
    if (!isMuted) {
        return false;
    }
    if (!muteUntil) {
        return true;
    }
    const currentDate = new Date();
    const muteUntilDate = new Date(muteUntil);
    return muteUntilDate > currentDate;
}