export const replaceIdWithNameUsingUnformattedList = (
  text,
  unFormattedList,
  spanText = false
) => {
  var mentionIdList = getFormatedListForUserAndAttendees(unFormattedList);

  if (spanText)
    return replaceIdWithNameAndCreateSpanFromText(text, mentionIdList);
  else return replaceIdWithName(text, mentionIdList);
};

export const getFormatedListForUserAndAttendees = (unFormattedList) => {
  var newFormatedData = unFormattedList
    ? unFormattedList.map((u) => {
      const fullName = u?.display_name ? `${u?.display_name}` : "";
      return {
        fullName,
        id: u?._id,
        profileImg: u?.profileImg ?? "",
        onlineStatus: u?.onlineStatus,
      };
    })
    : [];
  return newFormatedData;
};

export const replaceIdWithNameAndCreateSpanFromText = (text, mentionIdList) => {
  const updatedText = [];
  let mentionText;
  // Split text by newline characters if present
  const parts = text.includes("\n") ? text.split("\n") : [text];

  parts.forEach((part, partIndex) => {
    const words = part.split(" ").filter((word) => word.trim() !== ""); // Remove empty strings
    words.forEach((word, wordIndex) => {
      const trimmedWord = word.trim();
      if (trimmedWord.startsWith("@")) {
        // Process mentions
        const cleanedMention = trimmedWord
          .replace(/[^a-fA-F0-9]/g, "")
          .substring(0, 24); // Limit to 24 characters
        if (cleanedMention.length === 24) {
          const user = mentionIdList.find((user) => user.id === cleanedMention);
          const mentionText = user ? `@${user.fullName}` : trimmedWord;
          updatedText.push(mentionText);
        } else {
          updatedText.push(trimmedWord); // Push the original word if it's not a valid ObjectId
        }
      } else {
        updatedText.push(trimmedWord);
      }

      // Add a space after each word except the last one in the part
      if (wordIndex < words.length - 1) {
        updatedText.push(" ");
      }
    });

    // Add a newline character after each part except the last
    if (partIndex < parts.length - 1) {
      updatedText.push("\n");
    }
  });

  return updatedText;
};

export const replaceIdWithName = (text, mentionIdList) => {
  let updatedText = [];
  if (text?.includes("@") && mentionIdList.length > 0) {
    text.split(" ").map((textItem) => {
      if (textItem.startsWith("@")) {
        const cleanedMention = textItem
          ?.replace(/^@/, "")
          .replace(/[^a-zA-Z0-9]*$/, "");
        let getUser = mentionIdList.filter((user) => {
          if (user.id === cleanedMention.replace("@", "")) {
            return user;
          }
        });
        let changeIdwithname = getUser.length
          ? `@${getUser[0].fullName}`
          : textItem;
        updatedText.push(changeIdwithname);
      } else {
        updatedText.push(textItem);
      }
    });
    return updatedText.join(" ");
  } else {
    return text;
  }
};

export const replaceNameWithId = (text, mentionIdList) => {
  let updatedText = [];
  if (text?.includes("@") && mentionIdList.length > 0) {
    text.split(" ").map((textItem) => {
      if (textItem.startsWith("@")) {
        const cleanedMention = textItem
          ?.replace(/^@/, "")
          .replace(/[^a-zA-Z0-9]*$/, "");
        let getUser = mentionIdList.filter((user) => {
          if (user.id === cleanedMention.replace("@", "")) {
            return user;
          }
        });
        let changeNameWithId = getUser.length ? `@${getUser[0].id}` : textItem;
        updatedText.push(changeNameWithId);
      } else {
        updatedText.push(textItem);
      }
    });
    return updatedText.join(" ");
  } else {
    return text;
  }
};
