import createApiInstance from "./createApiInstance";
import { ApiRoutes } from "./apiRoutes";

export const extendedApi = createApiInstance.injectEndpoints({
  endpoints: (builder) => ({
    mainEventUserCount: builder.mutation({
      query: (body) => {
        return {
          url: ApiRoutes.event.mainEventUserCount.url,
          method: ApiRoutes.event.mainEventUserCount.method,
          body,
        };
      },
      transformResponse: (response) => response,
    }),
    getAllEventCities: builder.query({
      query: () => {
        return {
          url: ApiRoutes.event.getAllCities.url,
          method: ApiRoutes.event.getAllCities.method,
        };
      },
      transformResponse: (response) => response,
    }),
    addCheckIn: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: ApiRoutes.event.addCheckIn.url.replace(":id", id),
          method: ApiRoutes.event.addCheckIn.method,
          body,
        };
      },
      invalidatesTags: ["AllCheckinList"],
      transformResponse: (response) => response,
    }),
    getAllCheckInList: builder.query({
      query: ({ event_id, params }) => {
        return {
          url: ApiRoutes.event.checkInList.url.replace(":event_id", event_id),
          method: ApiRoutes.event.checkInList.method,
          params,
        };
      },
      providesTags: ["AllCheckinList"],
      transformResponse: (response) => response,
    }),
    getCheckInDetail: builder.query({
      query: ({ params }) => {
        return {
          url: ApiRoutes.event.checkInDetail.url,
          method: ApiRoutes.event.checkInDetail.method,
          params,
        };
      },
      providesTags: ["EventAttendee"],
      transformResponse: (response) => response,
    }),
    getEventAttendeeById: builder.query({
      query: ({ attendee_id, params }) => {
        return {
          url: ApiRoutes.event.getEventAttendeeById.url.replace(
            ":attendee_id",
            attendee_id
          ),
          method: ApiRoutes.event.getEventAttendeeById.method,
          params,
        };
      },
      providesTags: ["EventAttendee"],
      transformResponse: (response) => response,
    }),
    searchEventParticipantTypes: builder.query({
      query: ({ event_id, params }) => {
        return {
          url: ApiRoutes.event.searchEventParticipantTypes.url.replace(
            ":event_id",
            event_id
          ),
          method: ApiRoutes.event.searchEventParticipantTypes.method,
          params,
        };
      },
      providesTags: ["EventParticipantTypes"],
      transformResponse: (response) => response,
    }),
    editEventAttendees: builder.mutation({
      query: ({ attendee_id, body }) => {
        return {
          url: ApiRoutes.event.editEventAttendees.url.replace(
            ":attendee_id",
            attendee_id
          ),
          method: ApiRoutes.event.editEventAttendees.method,
          body,
        };
      },
      invalidatesTags: ["EventAttendee", "EventAttendees", "AllCheckinList"],
      transformResponse: (response) => response,
    }),
    getAllAttendeeListForRules: builder.query({
      query: () => {
        return {
          url: ApiRoutes.event.getAllAttendeeListForRules.url,
          method: ApiRoutes.event.getAllAttendeeListForRules.method,
        };
      },
      transformResponse: (response) => response,
    }),
    addImportedQuestions: builder.mutation({
      query: ({ eventId, body }) => {
        return {
          url: ApiRoutes.event.addImportedQuestions.url.replace(":id", eventId),
          method: ApiRoutes.event.addImportedQuestions.method,
          body,
        };
      },
      transformResponse: (response) => response,
    }),
    checkInAttendeeSuggestionList: builder.query({
      query: ({ eventId }) => {
        return {
          url: ApiRoutes.event.checkInSuggestionList.url.replace(
            ":id",
            eventId
          ),
          method: ApiRoutes.event.checkInSuggestionList.method,
        };
      },
      transformResponse: (response) => response,
    }),
    checkInActivityList: builder.query({
      query: ({ eventId }) => {
        return {
          url: ApiRoutes.event.checkInActivityList.url.replace(":id", eventId),
          method: ApiRoutes.event.checkInActivityList.method,
        };
      },
      transformResponse: (response) => response,
    }),
    checkInTicketList: builder.query({
      query: ({ eventId }) => {
        return {
          url: ApiRoutes.event.checkInTicketList.url.replace(":id", eventId),
          method: ApiRoutes.event.checkInTicketList.method,
        };
      },
      transformResponse: (response) => response,
    }),
    getEventDetail: builder.query({
      query: ({ event_id }) => {
        return {
          url: ApiRoutes.event.getEventById.url.replace(":event_id", event_id),
          method: ApiRoutes.event.getEventById.method,
        };
      },
      transformResponse: (response) => response,
    }),
    checkInStatus: builder.mutation({
      query: ({ body }) => {
        return {
          url: ApiRoutes.event.checkInStatus.url,
          method: ApiRoutes.event.checkInStatus.method,
          body,
        };
      },
      transformResponse: (response) => response,
    })
  }),
});

export const {
  useMainEventUserCountMutation,
  useGetAllEventCitiesQuery,
  useAddCheckInMutation,
  useGetAllCheckInListQuery,
  useLazyGetAllCheckInListQuery,
  useLazyGetEventAttendeeByIdQuery,
  useSearchEventParticipantTypesQuery,
  useEditEventAttendeesMutation,
  useLazyGetCheckInDetailQuery,
  useAddImportedQuestionsMutation,
  useCheckInAttendeeSuggestionListQuery,
  useGetAllAttendeeListForRulesQuery,
  useCheckInActivityListQuery,
  useCheckInTicketListQuery,
  useGetEventDetailQuery,
  useCheckInStatusMutation
} = extendedApi;
