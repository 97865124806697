import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import authService from "../../../Services/auth.service";
import newsocket from "../../../components/SocketConnect";
import authServiceChannel from "../../../Services/auth.header.channel";
import "./ChatStyle/ChatPopup.css";
import {
  getMuteChatIdListForUser,
  clearchatfromlist,
  clearchatredu,
} from "../../../Action";
import ChatHeading from "../Components/ChatHeading";
import ModalPopup from "../../../components/ModalPopup/ModalPopup";
import Typography from "../../../components/Typography/Typography";
import MuteChatModal from "../Components/MuteChatModal";
import { checkMuteStatusForList } from "../CommonFunctions/CheckMuteChat";

const ChatHeaderContent = ({
  showRightSidebar,
  setShowRightSidebar,
  allChatHeaderDetail,
  setAllChatHeaderDetail,
  groupMemberList,
  setEditGroup,
}) => {
  const { userid, type } = useParams();
  const dispatch = useDispatch();
  const history = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenClearChat, setIsModalOpenClearChat] = useState(false);
  const [leaveClearHistoryLoader, setClearHistoryLoader] = useState(false);
  const [openModelLeave, setOpenModelLeave] = useState(false);
  const [leaveGroupChannelLoader, setGroupChannelLoader] = useState(false);
  const [openDeleteGroupModal, setOpenDeleteGroupModal] = useState(false);
  const [deleteGroupLoader, setDeleteGroupLoader] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const chatListData = useSelector((state) => {
    return state.ReduAllChatList;
  });

  const chatListDataForChannel = useSelector((state) => {
    return state.ReduAllChatListChannel;
  });

  // Check if the chat is starred in the chatListData
  let chatData;
  if (type === "chatChannel") {
    chatData = chatListDataForChannel.find((chat) => chat.id === userid);
  } else {
    chatData = chatListData.find((chat) => chat.id === userid);
  }
  const isPinned = chatData?.isStar || false;

  // Get notification list common function
  const getMuteNotificationList = async () => {
    dispatch(getMuteChatIdListForUser());
  };

  useEffect(() => {
    //calling function for mute notification list
    getMuteNotificationList();
    document.addEventListener("keydown", handleKeyPress);

    // Clean up function to remove event listener
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  // Event listener for keyboard keys
  const handleKeyPress = (event) => {
    if (event.keyCode === 27) {
      setIsModalOpen(false);
      setIsModalOpenClearChat(false)
      setOpenModelLeave(false)
      setOpenDeleteGroupModal(false)
    }
  };

  // For get member profile
  useEffect(() => {
    setIsLoader(true);
    if (type !== "userChatGroup" && type !== "chatChannel") {
      authService.getmemberprofile(userid).then((response) => {
        if (response.data.status) {
          setAllChatHeaderDetail({
            ...allChatHeaderDetail,
            [response.data.data._id]: { ...response.data.data, type: "user" },
          });
          setIsLoader(false);
        }
      });
    } else if (type === "chatChannel") {
      authServiceChannel
        .getChannelDetailByIdFrontend(userid)
        .then((response) => {
          if (response.data.status) {
            setAllChatHeaderDetail({
              ...allChatHeaderDetail,
              [userid]: { ...response.data.channelData },
            });
            setIsLoader(false);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      authService
        .getGroupAndMemberDetails(userid)
        .then((res) => {
          if (res.data.status) {
            if (res.data.data[0])
              setAllChatHeaderDetail({
                ...allChatHeaderDetail,
                [res.data.data[0]._id]: {
                  ...res.data.data[0],
                  type: "userChatGroup",
                },
              });
            setIsLoader(false);
          }
        })
        .catch((e) => {});
    }
  }, [userid, type, groupMemberList]);

  //handle info click in header content
  const handleInfoClick = () => {
    setShowRightSidebar(!showRightSidebar);
    if (showRightSidebar) {
      localStorage.setItem("isChatInfo", false);
    } else {
      localStorage.setItem("isChatInfo", true);
    }
  };

  //open mute notification modal box
  const handleonClickMuteNotification = () => {
    setIsModalOpen(true);
  };

  // handle un-mute notification
  const handleonClickUnMuteNotification = () => {
    newsocket.emit("muteChat", {
      chatId: userid,
      userId: localStorage.getItem("userid"),
      muteUntil: null,
    });
  };

  // handle star user
  const handleStarUser = async () => {
    newsocket.emit("starChat", {
      chatId: userid,
      type: type,
      userId: localStorage.getItem("userid"),
    });
  };

  // onselect heaader 3 dot select
  const onSelect = (selectedOption) => {
    selectedOption.name === "Mute notification" &&
      handleonClickMuteNotification();
    selectedOption.name === "Unmute notification" &&
      handleonClickUnMuteNotification();
    selectedOption.name === "Leave channel" && handleLeaveGroup();
    selectedOption.name === "Leave group" && handleLeaveGroup();
    selectedOption.name === "Edit group" && editGroupFun();
    selectedOption.name === "Clear history" && handleClearHistory();
    selectedOption.name === "Delete group" && handleDeleteGroup();
  };

  // handle Leave Group modalbox
  const handleLeaveGroup = () => {
    setOpenModelLeave(true);
  };

  // handle Delete modal box
  const handleDeleteGroup = () => {
    setOpenDeleteGroupModal(true);
  };

  //handle function Clear Chat history
  const handleClearChat = () => {
    dispatch(clearchatredu(userid));
    dispatch(clearchatfromlist(userid));
    setClearHistoryLoader(true);
    authService
      .clearchat({
        id: userid,
        type: type,
        deleteConversation: false,
      })
      .then((res) => {
        if (res.data.status) {
          newsocket.emit("getLastMessage", {
            roomId: userid,
            userId: localStorage.getItem("userid"),
          });
          // newsocket.emit("newChatList", {
          //   id: localStorage.getItem("userid"),
          // });
        }
      })
      .catch((e) => {});
    setClearHistoryLoader(false);
    setIsModalOpenClearChat(false);
  };

  // open modalbox for clear chat history
  const handleClearHistory = () => {
    setIsModalOpenClearChat(true);
  };

  // close modalbox for clear chat history
  const handleClose = () => {
    setIsModalOpenClearChat(false);
  };

  // edit group open drawer
  const editGroupFun = () => {
    setEditGroup(true);
    setShowRightSidebar(true);
  };
  // handle close leave group channel popup
  const handleCloseLeaveGroupChannel = () => {
    setOpenModelLeave(false);
  };

  //get Channel Name
  const ChannelName =
    type === "chatChannel"
      ? allChatHeaderDetail[userid]?.channelName
      : type === "userChatGroup"
      ? allChatHeaderDetail[userid]?.groupTitle
      : "";

  // get Group name
  const GroupName =
    type === "userChatGroup" ? allChatHeaderDetail[userid]?.groupTitle : "";

  // get User name for display
  const userName =
    type === "user"
      ? allChatHeaderDetail &&
        allChatHeaderDetail[userid] &&
        (allChatHeaderDetail[userid]?.display_name)
        ? `${allChatHeaderDetail[userid]?.display_name}`
        : ""
      : "";

  // handle Leave channel group fun
  const handleLeaveChannelFun = () => {
    if (type === "userChatGroup") {
      newsocket.emit("leave-group", {
        groupid: userid,
        authUserId: localStorage.getItem("userid"),
        time_stamp: moment.utc().format("YYYY-MM-DDTHH:mm:ss.SSSSSS[Z]"),
      });
      newsocket.emit("leaveRoom", { roomId: userid });
      dispatch({
        type: "DELETECHATFROMLIST",
        payload: userid,
      });
      dispatch(clearchatredu(userid));

      setOpenModelLeave(false);
      history("/chats/me/me");
    } else if (type === "chatChannel") {
      newsocket.emit("leave-channel", {
        channelId: userid,
        authUserId: localStorage.getItem("userid"),
        time_stamp: moment.utc().format("YYYY-MM-DDTHH:mm:ss.SSSSSS[Z]"),
      });
      newsocket.emit("leaveRoom", {
        roomId: userid,
      });
      dispatch({
        type: "DELETECHATFROMLIST",
        payload: userid,
      });
      setOpenModelLeave(false);
      dispatch(clearchatredu(userid));

      history("/chats/me/me");
    }
  };

  //handleCloseDeleteGroup
  const handleCloseDeleteGroup = () => {
    setOpenDeleteGroupModal(false);
  };

  //handle delete group func
  //when admin delete and leave a group this get called
  const handleDeleteGroupFun = () => {
    newsocket.emit("delete-group", {
      groupid: userid,
      authUserId: localStorage.getItem("userid"),
    });
    newsocket.emit("leaveRoom", {
      roomId: userid,
    });
    dispatch({
      type: "DELETECHATFROMLIST",
      payload: userid,
    });
    dispatch(clearchatredu(userid));
    setOpenDeleteGroupModal(false);
    history("/chats/me/me");
  };

  const CheckMuteStatus = () => {
    let muteStatus = false;
    if (type === "userChatGroup" || type === "user") {
      const userMessages = chatListData?.filter(
        (muteData) => muteData.id === userid
      );
      muteStatus = checkMuteStatusForList(
        userMessages[0]?.isMuted,
        userMessages[0]?.muteUntil
      );
    } else if (type === "chatChannel") {
      const userChannels = chatListDataForChannel?.filter(
        (muteData) => muteData.id === userid
      );
      muteStatus = checkMuteStatusForList(
        userChannels[0]?.isMuted,
        userChannels[0]?.muteUntil
      );
    }
    return muteStatus;
  };
  const isMuted = CheckMuteStatus();
  
  
  return (
    <>
      <ChatHeading
        onSelect={onSelect}
        isAdmin={
          type === "userChatGroup" &&
          groupMemberList?.members &&
          groupMemberList?.members?.filter(
            (member) =>
              member.userId === localStorage.getItem("userid") &&
              member.user_type === "adminuser"
          ).length
            ? true
            : false
        }
        isMuted={isMuted}
        filled={isPinned}
        onlineStatus="online"
        type={
          type === "userChatGroup"
            ? "Group"
            : type === "chatChannel"
            ? "Channel"
            : type === "user"
            ? "AvtarName"
            : ""
        }
        ChannelName={
          type === "chatChannel" && groupMemberList?.channelData?.channelName
            ? groupMemberList.channelData.channelName
            : ChannelName
        }
        GroupName={
          type === "userChatGroup" && groupMemberList?.groupTitle
            ? groupMemberList?.groupTitle
            : GroupName
        }
        AvtarName={userName}
        avtarType={
          type === "chatChannel"
            ? groupMemberList?.channelData &&
              groupMemberList?.channelData?.channelIcon?.length
              ? "photo"
              : "letter"
            : type === "userChatGroup"
            ? groupMemberList?.groupImage && groupMemberList?.groupImage?.length
              ? "photo"
              : "letter"
            : type === "user"
            ? allChatHeaderDetail &&
              allChatHeaderDetail[userid]?.profileImg?.length
              ? "photo"
              : "letter"
            : ""
        }
        avtarSrc={
          type === "chatChannel"
            ? groupMemberList?.channelData &&
              groupMemberList?.channelData?.channelIcon?.length
              ? process.env.REACT_APP_AWS_IMG_VID_PATH +
                groupMemberList?.channelData?.channelIcon
              : ""
            : type === "userChatGroup"
            ? groupMemberList?.groupImage && groupMemberList?.groupImage?.length
              ? groupMemberList?.groupImage.split("?")[0].split("/")[
                  groupMemberList?.groupImage.split("?")[0].split("/").length -
                    1
                ].length && groupMemberList?.groupImage.startsWith("https")
                ? groupMemberList?.groupImage
                : groupMemberList?.groupImage &&
                  groupMemberList?.groupImage.split("?")[0].split("/")[
                    groupMemberList?.groupImage.split("?")[0].split("/")
                      .length - 1
                  ].length
                ? process.env.REACT_APP_AWS_IMG_VID_PATH +
                  groupMemberList?.groupImage
                : ""
              : ""
            : type === "user"
            ? allChatHeaderDetail &&
              allChatHeaderDetail[userid]?.profileImg?.length
              ? allChatHeaderDetail[userid].profileImg
              : ""
            : ""
        }
        avtarLetter={
          type === "chatChannel"
            ? groupMemberList?.channelData &&
              groupMemberList?.channelData?.channelName?.charAt(0)
            : type === "userChatGroup"
            ? groupMemberList?.groupTitle &&
              groupMemberList?.groupTitle?.charAt(0)
            : type === "user"
            ? userName.charAt(0)
            : ""
        }
        handleInfoClick={handleInfoClick}
        handleStarUser={handleStarUser}
        handleonClickMuteNotification={handleonClickMuteNotification}
        handleonClickUnMuteNotification={handleonClickUnMuteNotification}
        isLoader={isLoader}
      />

      {/* Mute chat Modal */}
      {isModalOpen && (
        <MuteChatModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          userid={userid}
        />
      )}

      {/* clear history Modal box */}
      <ModalPopup
        onClose={handleClose}
        isFooter={true}
        isHeader={false}
        modelSize="648"
        isOpen={isModalOpenClearChat}
        buttonText1="Clear"
        buttonText="Cancel"
        btn2variant="danger"
        handleClick2ndButton={handleClearChat}
        handleClick={handleClose}
        disabled={leaveClearHistoryLoader}
        hasLoading={leaveClearHistoryLoader}
        spinnersType="white"
        disabled1stbtn={leaveClearHistoryLoader}
      >
        <div className="channel-group-wrapper">
          <Typography variant="h3">Clear history</Typography>
          <Typography variant="p">
            Are you sure you want to clear the chat history in&nbsp;
            <b>
              {type === "userChatChannel"
                ? ChannelName
                : type === "user"
                ? userName
                : GroupName}
            </b>
            ?
          </Typography>
        </div>
      </ModalPopup>

      {/* Leave Channel or Group Modal box */}
      <ModalPopup
        onClose={handleCloseLeaveGroupChannel}
        isFooter={true}
        isHeader={false}
        modelSize="648"
        isOpen={openModelLeave}
        buttonText1="Leave"
        buttonText="Cancel"
        btn2variant="danger"
        handleClick2ndButton={handleLeaveChannelFun}
        handleClick={handleCloseLeaveGroupChannel}
        disabled={leaveGroupChannelLoader}
        hasLoading={leaveGroupChannelLoader}
        spinnersType="white"
        disabled1stbtn={leaveGroupChannelLoader}
      >
        <div className="channel-group-wrapper">
          <Typography variant="h3">
            Leave
            {type === "userChatGroup"
              ? " group"
              : type === "chatChannel"
              ? " channel"
              : ""}
          </Typography>
          <Typography variant="p">
            Are you sure you want to leave{" "}
            <b>
              {type === "userChatChannel"
                ? ChannelName
                : type === "user"
                ? userName
                : GroupName}
            </b>
            &nbsp;
            {type === "userChatGroup"
              ? "group"
              : type === "chatChannel"
              ? "channel"
              : ""}
            ?
          </Typography>
        </div>
      </ModalPopup>

      {/* Delete Group Modal box */}
      <ModalPopup
        onClose={handleCloseDeleteGroup}
        isFooter={true}
        isHeader={false}
        modelSize="648"
        isOpen={openDeleteGroupModal}
        buttonText1="Delete"
        buttonText="Cancel"
        btn2variant="danger"
        handleClick2ndButton={handleDeleteGroupFun}
        handleClick={handleCloseDeleteGroup}
        disabled={deleteGroupLoader}
        hasLoading={deleteGroupLoader}
        spinnersType="white"
        disabled1stbtn={deleteGroupLoader}
      >
        <div className="channel-group-wrapper">
          <Typography variant="h3">Delete group</Typography>
          <Typography variant="p">
            Are you sure you want to delete
            <b>{GroupName}</b> ?
          </Typography>
          {/* <div className="delete-group-checkbox-wrapper">
              <Checkbox
                hasLabel={true}
                label="Delete the group for all members"
              />
            </div> */}
        </div>
      </ModalPopup>
    </>
  );
};

export default ChatHeaderContent;
