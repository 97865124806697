import React, { useEffect, useRef } from "react";
import Header from "../../components/UserComponents/Dashboard/Header";
import LeftSidebar from "../../components/UserComponents/Dashboard/LeftSidebar";
import DocumentMainPage from "./DocumentMainPage";
import "./Components/Document.css";
import AccessDeny from "../../components/AccessDeny";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

const Index = () => {
  useEffect(() => {
    sessionStorage.setItem("previousurl", window.location.href);
  }, []);
  const openApp = () => {
    const appDeepLink = "com.app.mdscommunity://dev.milliondollarsellers.com/documents"; // Replace with your app's deep link
    const storeLink = "https://play.google.com/store/apps/details?id=com.app.mdscommunity"; // Play Store link for Android
    const iosStoreLink = "https://apps.apple.com/in/app/mds-community/id1636838955"; // App Store link for iOS
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

    if (isIOS && isSafari) {
      try {
        // Attempt to open the app link
        console.log("try");
        window.location.replace(appDeepLink);
      } catch (error) {
        // If it fails, fall back to App Store
        console.log("catch");
        window.location.href = iosStoreLink;
      }
    } else {
      // Fallback for other browsers or platforms
      window.location.href = appDeepLink;
    }

    // Set a timer to check if the user has the app installed
    const timeout = setTimeout(() => {
      // If the app didn't open, fall back to the appropriate store
      if (navigator.userAgent.includes("Android")) {
        window.location.href = storeLink;
      } else if (navigator.userAgent.includes("iPhone") || navigator.userAgent.includes("iPad")) {
        window.location.href = iosStoreLink;
      }
    }, 2000);

    // Try to open the app
    // window.location.href = appDeepLink;

    // Clear the timer if the user navigates away, indicating the app opened successfully
    window.onblur = () => clearTimeout(timeout);
  };
  const resourceList = localStorage.getItem("accessResources");
  const isCollaborator = JSON.parse(localStorage.getItem("isCollaborator"));
  const partnerResource =
    resourceList !== null &&
    JSON.parse(resourceList).find((resource) => resource.name === "Documents");

  return (
    <>
      <Helmet>
        <meta
          property="al:ios:url"
          content="com.app.mdscommunity://dev.milliondollarsellers.com/documents"
        />
        <meta property="al:ios:app_store_id" content="1636838955" />
        <meta property="al:ios:app_name" content="MDSCommunity" />
        <meta property="og:title" content="Document" />
        <meta property="og:type" content="website" />
        <meta
          property="al:android:url"
          content="com.app.mdscommunity://dev.milliondollarsellers.com/documents"
        />
        <meta property="al:android:package" content="com.app.mdscommunity" />
        <meta property="al:android:app_name" content="MDS Community" />
        <meta
          property="al:web:url"
          content="https://dev.milliondollarsellers.com/documents"
        />
      </Helmet>
      {isCollaborator && partnerResource !== undefined && partnerResource ? (
        <>
          <Header />
          <div className="all-content-main-section">
            <LeftSidebar />
            <div className="all-content-main-section-in">
              <div className="doc-management-main">
                <DocumentMainPage />
              </div>
            </div>
          </div>
        </>
      ) : isCollaborator !== null && !isCollaborator ? (
        <>
          <Header />

          <div className="all-content-main-section">
            <LeftSidebar />
            <div className="all-content-main-section-in">
              <div className="doc-management-main">
                <button onClick={openApp}>Open App</button>
                <DocumentMainPage />
              </div>
            </div>
          </div>
        </>
      ) : isCollaborator && partnerResource === undefined ? (
        <AccessDeny />
      ) : (
        <></>
      )}

    </>
  );
};

export default Index;
