const initialState = {
    totalMessages: 0,
    totalChannels: 0,
    // ...other state
};

const ReduChatListLoadMore = (state = initialState, action) => {
    switch (action.type) {
        case "SET_TOTAL_MESSAGES":
            return {
                ...state,
                totalMessages: action.payload,  // Set total messages count
            };
        case "SET_TOTAL_CHANNELS":
            return {
                ...state,
                totalChannels: action.payload,  // Set total channels count
            };
        case "SET_PAGE_FOR_MESSAGE":
            return {
                ...state,
                pageForMessage: action.payload,  // Update page number for messages
            };
        case "SET_PAGE_FOR_CHANNEL":
            return {
                ...state,
                pageForChannel: action.payload,  // Update page number for channels
            };
        default:
            return state;
    }
}


export default ReduChatListLoadMore;