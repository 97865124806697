import React, { useState } from "react";
import PropTypes from "prop-types";

import { useDispatch, useSelector } from "react-redux";
import { InView } from "react-intersection-observer";

// Import
import ChatMessagesOneLine from "./ChatMessagesOneLine";
import ChatMessageReply from "./ChatMessageReply";
import ChatMessageLink from "./ChatMessageLink";
import ChatMessageImage from "./ChatMessageImage";
import ChatMessageVideo from "./ChatMessageVideo";
import ChatMessagesFile from "./ChatMessagesFile";
import ChatMessagesVoiceMessage from "./ChatMessagesVoiceMessage";
import ChatMessagesEmoji from "./ChatMessagesEmoji";
import ChatUnreadDivider from "./ChatUnreadDivider";
// Icons
import CopyIcon from "../../../components/Icons/GeneralIcons/CopyIcon";
import DeleteIcon from "../../../components/Icons/GeneralIcons/DeleteIcon";
import EditIcon from "../../../components/Icons/GeneralIcons/EditIcon";
import ComplainIcon from "../../../components/Icons/GeneralIcons/ComplainIcon";
import CompressionProgress from "./CompressionProgress";

const StackedMessagesContainer = ({
  menuSelectedDivRef,
  hoverStay,
  findQuotedmessage = () => { },
  quoteMessageSearchIdRef,
  handleLoadMoreMessages = () => { },
  showScrollButton,
  unReadMessageRef,
  // unreadMessageIndex = "",
  type = "Channel/Group",
  isOutgoing = false,
  actionModel,
  setActionModel,
  AvatarType = "photo",
  Avtarsrc = "https://mds-community.s3.amazonaws.com/uploads/users/profile/original-64b7745f39249e342e0bf769-profile-2023-09-05T16:23:12.455901.png",
  AvtarLetter = "M",
  AvtarIcon = "",
  handleReplyClick,
  handleDotsClick,
  handleReactionClick,
  handleEmojiClick,
  showAllEmogis = false,
  toggleShowEmojis,
  initialEmojis,
  reactions,
  setReactions,
  showEmojis = false,
  showDropdown = false,
  handleSelectEmoji,
  setShowDropdown,
  setShowEmojis,
  reactionMsg,
  handleReactionUsersList,
  hoverEffects,
  setHoverEffects,
  chatData = [
    {
      messageType: "text", // text, reply, link, image, video, file, emojis, voice
      // Main Components Prompts
      isHighlighted: false,
      hasName: true,
      hasReactions: false,
      hasAction: false,
      name: "Jane",
      message: "Hii I am really good.",

      // OutgoingMessageTime
      isRead: true,
      hasBackdrop: false,
      isEdited: false,
      time: "09:12 AM",

      // Reaction
      hasCounter: true,
      hasReaction2: false,
      hasReaction3: false,
      hasReaction4: false,
      emoji1: "👍",
      emoji2: "😂",
      emoji3: "👌",
      emoji4: "🙌",
      counter: 5,
    },
    {
      messageType: "reply",
      // Main Components Prompts

      isHighlighted: false,

      hasName: true,
      hasReactions: false,
      hasAction: false,
      name: "Jane",
      message: "Hii I am really good.",

      // OutgoingMessageTime
      isRead: true,
      hasBackdrop: false,
      isEdited: false,
      time: "09:12 AM",

      // Reaction
      hasCounter: true,
      hasReaction2: false,
      hasReaction3: false,
      hasReaction4: false,

      counter: 5,

      //  Reply Message type
      receiverName: "Bill Gaston",
      srcOfReplayMessage:
        "https://mds-community.s3.amazonaws.com/uploads/users/profile/original-64b7745f39249e342e0bf769-profile-2023-09-05T16:23:12.455901.png",
      messageToReply:
        "Really appreciate the and @Wade variations of your recipes. So thoughtful of you! 🌱",
      altReply: "Image",
      MessageReplyType: "MessageReply",
    },
    {
      messageType: "link",
      // meta detail
      metaImageSrc:
        "https://mds-community.s3.amazonaws.com/uploads/users/profile/original-64b7745f39249e342e0bf769-profile-2023-09-05T16:23:12.455901.png",
      metaTitle: "mds.com",
      metaLink: "https://mds.com",
      metaDescription: "Financelab X - Webflow Ecommerce Website Template",
      metaShortDescription:
        "If you are a fintech startup looking for a great finance Webflow Template, search no more. Presenting Financelab X, the next-gen template for companies in the finance sector.",

      // Main Components Prompts
      isHighlighted: false,

      hasName: true,
      hasReactions: false,
      hasAction: false,
      name: "Jane",
      message: "Hii I am really good.",

      // OutgoingMessageTime
      isRead: true,
      hasBackdrop: false,
      isEdited: false,
      time: "09:12 AM",

      // Reaction
      hasCounter: true,
      hasReaction2: false,
      counter: 5,
    },
    {
      messageType: "image",
      // Main Components Prompts
      isHighlighted: false,

      hasReactions: false,
      hasAction: false,
      hasTime: true,
      src: [
        "https://images.unsplash.com/photo-1705392852198-2a2334db0314?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",

        // "https://images.unsplash.com/photo-1504415724575-56cef5e3da72?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        // "https://images.unsplash.com/photo-1704525083773-a066e2b40c86?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        // "https://images.unsplash.com/photo-1668086620216-851f13fbce3b?q=80&w=1935&auto=format&fit=crop&ixl/ib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      ],
      count: 2,

      // OutgoingMessageTime
      isRead: true,
      hasBackdrop: false,
      isEdited: false,
      time: "09:12 AM",

      // Reaction
      hasCounter: true,
      hasReaction2: false,
      counter: 5,
    },
    {
      messageType: "video",
      // Main Components Prompts
      isHighlighted: false,

      hasReactions: false,
      hasAction: false,
      hasTime: true,
      src: [
        "https://images.unsplash.com/photo-1705392852198-2a2334db0314?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "https://images.unsplash.com/photo-1504415724575-56cef5e3da72?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        // "https://images.unsplash.com/photo-1704525083773-a066e2b40c86?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        // "https://images.unsplash.com/photo-1668086620216-851f13fbce3b?q=80&w=1935&auto=format&fit=crop&ixl/ib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      ],
      count: 2,
      videoType: "Horizontal",

      // OutgoingMessageTime
      isRead: true,
      hasBackdrop: false,
      isEdited: false,
      time: "09:12 AM",

      // Reaction
      hasCounter: true,
      hasReaction2: false,
      counter: 5,
    },
    {
      messageType: "file",
      // Main Components Prompts
      isHighlighted: false,

      hasReactions: false,
      hasAction: false,
      files: {
        fileName: "filename.svg",
        size: "55KB",
      },

      // OutgoingMessageTime
      isRead: true,
      hasBackdrop: false,
      isEdited: false,
      time: "09:12 AM",

      // Reaction
      hasCounter: true,
      hasReaction2: false,
      counter: 5,
    },
    {
      messageType: "voice",
      // Main Components Prompts

      isHighlighted: false,

      hasReactions: false,
      hasAction: false,
      voiceControlType: false,
      voiceWaveFilled: false,
      audioTime: "2:15",

      // OutgoingMessageTime
      isRead: true,
      hasBackdrop: false,
      isEdited: false,
      time: "09:12 AM",

      // Reaction
      hasCounter: true,
      hasReaction2: false,
      counter: 5,
    },
    {
      messageType: "emoji",
      // Main Components Prompts
      isHighlighted: false,
      hasReactions: false,
      hasAction: false,
      message: "😊😂🤯✏️",

      // Reaction
      hasCounter: true,
      hasReaction2: false,
      counter: 5,
    },
  ],
  onSelectOptions,
  handlePreviewClick,
  msgData,
  updateMessageMetadata
}) => {
  const unreadMessageIndex = useSelector((state) => {
    return state.ReduUnreadMessageIndex;
  });

  const generateContextMenuOptions = (chat) => {
    const commonOptions = [
      {
        icon: (
          <ComplainIcon
            height={24}
            width={24}
            iconColor="var(--color-icon-neutral-strong)"
          />
        ),
        name: "Report",
        isError: false,
      },
    ];

    const textOptions = [
      {
        icon: (
          <CopyIcon
            height={24}
            iconColor="var(--color-icon-neutral-strong)"
            width={24}
          />
        ),
        name: "Copy text",
        isError: false,
      },
    ];

    let options = commonOptions;

    if (!isOutgoing) {
      options = [
        ...(chat?.messageType === "text" ||
          chat?.messageType === "reply" ||
          chat?.messageType === "emoji"
          ? textOptions
          : []),
        ...options,
      ];
    } else {
      options = [
        ...(chat?.messageType === "text" ||
          chat?.messageType === "reply" ||
          chat?.messageType === "emoji"
          ? [
            ...textOptions,
            {
              icon: (
                <EditIcon
                  height={24}
                  iconColor="var(--color-icon-neutral-strong)"
                  width={24}
                />
              ),
              name: "Edit message",
              isError: false,
            },
          ]
          : []),
        {
          icon: (
            <DeleteIcon
              height={24}
              iconColor="var(--color-icon-danger-strong)"
              width={24}
            />
          ),
          name: "Delete message",
          isError: true,
        },
      ];
    }
    return options;
  };

  function checkMessageType(array) {
    for (let i = 0; i < array.length; i++) {
      const obj = array[i];
      if (
        obj.messageType &&
        (obj.messageType === "image" ||
          obj.messageType === "video" ||
          obj.messageType === "file" ||
          obj.messageType === "voice" ||
          obj.messageType === "emoji")
      ) {
        return true; // Return true if messageType matches one of the specified types
      }
    }
    return false; // Return false if none of the objects have a valid message type
  }

  return (
    <>
      {chatData &&
        chatData.length &&
        chatData.map((chat, index) => {
          let isStacked = index === 0 ? false : true;

          let displayName;
          if (chat?.hasName) {
            if (index === chatData.length - 1) {
              displayName = true;
            } else {
              if (index === 0 && checkMessageType(chatData)) {
                displayName = true;
              } else {
                displayName = false;
              }
            }
          } else {
            displayName = false;
          }
          // let displayName = chat?.hasName
          //   ? index === chatData.length - 1
          //   : false;
          let showUnreadMessageDivider =
            chat && chat._id
              ? `${unreadMessageIndex}0` === chat._id
                ? true
                : false
              : false;
          const dropDownOptions = generateContextMenuOptions(chat);
          switch (chat?.messageType) {
            case "text":
              if (chat?.isLastMessage) {
                return (
                  <InView
                    onChange={(inView, entry) => {
                      if (inView) {
                        handleLoadMoreMessages();
                      }
                    }}
                  >
                    <ChatMessagesOneLine
                      menuSelectedDivRef={menuSelectedDivRef}
                      hoverStay={hoverStay}
                      messageInArray={chat.messageInArray}
                      uniqueId={chat._id}
                      actionModel={actionModel === chat._id + "-" + index}
                      setActionModel={setActionModel}
                      handleEmojiClick={handleEmojiClick}
                      msgData={msgData}
                      handleReactionClick={handleReactionClick}
                      handleReactionUsersList={handleReactionUsersList}
                      hoverEffects={hoverEffects}
                      setHoverEffects={setHoverEffects}
                      type={type}
                      isOutgoing={isOutgoing}
                      AvatarType={chat.AvatarType}
                      Avtarsrc={chat.Avtarsrc}
                      AvtarLetter={chat.AvtarLetter}
                      AvtarIcon={AvtarIcon}
                      // handleEmojiClick={handleEmojiClick}
                      handleReplyClick={() => {
                        handleReplyClick(chat);
                      }}
                      handleDotsClick={() =>
                        handleDotsClick((chat._id + "-" + index).toString())
                      }
                      // handleReactionClick={handleReactionClick}
                      isHighlighted={
                        quoteMessageSearchIdRef
                          ? quoteMessageSearchIdRef.current === chat._id
                          : true
                      }
                      isStacked={isStacked}
                      hasName={displayName}
                      hasAction={chat.hasAction}
                      name={chat.name}
                      message={chat.message}
                      // OutgoingMessageTime
                      isRead={chat.isRead}
                      hasBackdrop={chat.hasBackdrop}
                      isEdited={chat.isEdited}
                      time={chat.time}
                      // Reaction
                      hasCounter={chat.hasCounter}
                      hasReactions={chat?.hasReactions}
                      hasReaction2={chat?.hasReaction2}
                      hasReaction3={chat?.hasReaction3}
                      hasReaction4={chat?.hasReaction4}
                      counter={chat.counter}
                      emoji1={chat?.emoji1}
                      emoji2={chat?.emoji2}
                      emoji3={chat?.emoji3}
                      emoji4={chat?.emoji4}
                      showAllEmogis={showAllEmogis}
                      toggleShowEmojis={toggleShowEmojis}
                      initialEmojis={initialEmojis}
                      reactions={reactions}
                      setReactions={setReactions}
                      showEmojis={showEmojis}
                      showDropdown={showDropdown}
                      handleSelectEmoji={handleSelectEmoji}
                      setShowDropdown={setShowDropdown}
                      setShowEmojis={setShowEmojis}
                      reactionMsg={reactionMsg}
                      //Dropdown items
                      dropDownOptions={dropDownOptions}
                      onSelectOptions={onSelectOptions}
                      chatData={chat}
                      chatId={chat}
                      isInProgress={
                        chat._id && chat._id.slice(0, -1) === "undefined"
                          ? true
                          : false
                      }
                    />
                    {showUnreadMessageDivider && <ChatUnreadDivider />}
                  </InView>
                );
              } else {
                return (
                  <>
                    <ChatMessagesOneLine
                      menuSelectedDivRef={menuSelectedDivRef}
                      hoverStay={hoverStay}
                      messageInArray={chat.messageInArray}
                      uniqueId={chat._id}
                      actionModel={actionModel === chat._id + "-" + index}
                      setActionModel={setActionModel}
                      handleEmojiClick={handleEmojiClick}
                      handleReactionClick={handleReactionClick}
                      msgData={msgData}
                      handleReactionUsersList={handleReactionUsersList}
                      hoverEffects={hoverEffects}
                      setHoverEffects={setHoverEffects}
                      type={type}
                      isOutgoing={isOutgoing}
                      AvatarType={chat.AvatarType}
                      Avtarsrc={chat.Avtarsrc}
                      AvtarLetter={chat.AvtarLetter}
                      AvtarIcon={AvtarIcon}
                      // handleEmojiClick={handleEmojiClick}
                      handleReplyClick={() => {
                        handleReplyClick(chat);
                      }}
                      handleDotsClick={() =>
                        handleDotsClick((chat._id + "-" + index).toString())
                      }
                      // handleReactionClick={handleReactionClick}
                      isHighlighted={
                        quoteMessageSearchIdRef
                          ? quoteMessageSearchIdRef.current === chat._id
                          : false
                      }
                      isStacked={isStacked}
                      hasName={displayName}
                      hasAction={chat.hasAction}
                      name={chat.name}
                      message={chat.message}
                      // OutgoingMessageTime
                      isRead={chat.isRead}
                      hasBackdrop={chat.hasBackdrop}
                      isEdited={chat.isEdited}
                      time={chat.time}
                      // Reaction
                      hasCounter={chat.hasCounter}
                      hasReactions={chat.hasReactions}
                      hasReaction2={chat?.hasReaction2}
                      hasReaction3={chat?.hasReaction3}
                      hasReaction4={chat?.hasReaction4}
                      counter={chat.counter}
                      emoji1={chat?.emoji1}
                      emoji2={chat?.emoji2}
                      emoji3={chat?.emoji3}
                      emoji4={chat?.emoji4}
                      showAllEmogis={showAllEmogis}
                      toggleShowEmojis={toggleShowEmojis}
                      initialEmojis={initialEmojis}
                      reactions={reactions}
                      setReactions={setReactions}
                      showEmojis={showEmojis}
                      showDropdown={showDropdown}
                      handleSelectEmoji={handleSelectEmoji}
                      setShowDropdown={setShowDropdown}
                      setShowEmojis={setShowEmojis}
                      reactionMsg={reactionMsg}
                      //Dropdown items
                      dropDownOptions={dropDownOptions}
                      onSelectOptions={onSelectOptions}
                      chatData={chat}
                      chatId={chat}
                      isInProgress={
                        chat._id && chat._id.slice(0, -1) === "undefined"
                          ? true
                          : false
                      }
                    />
                    {showUnreadMessageDivider && <ChatUnreadDivider />}
                  </>
                );
              }
            case "reply":
              if (chat?.isLastMessage) {
                return (
                  <InView
                    onChange={(inView, entry) => {
                      if (inView) {
                        handleLoadMoreMessages();
                      }
                    }}
                  >
                    <ChatMessageReply
                      menuSelectedDivRef={menuSelectedDivRef}
                      messageToReplyArray={chat.messageToReplyArray}
                      messageInArray={chat.messageInArray}
                      uniqueId={chat._id}
                      handleClickOnRepliedMessage={() => {
                        findQuotedmessage(`${chat?.quote_message_id?._id}0`);
                      }}
                      actionModel={actionModel === chat._id + "-" + index}
                      setActionModel={setActionModel}
                      type={type}
                      isOutgoing={isOutgoing}
                      AvatarType={chat.AvatarType}
                      Avtarsrc={chat.Avtarsrc}
                      AvtarLetter={chat.AvtarLetter}
                      AvtarIcon={AvtarIcon}
                      handleEmojiClick={handleEmojiClick}
                      handleReplyClick={() => {
                        handleReplyClick(chat);
                      }}
                      handleDotsClick={() =>
                        handleDotsClick((chat._id + "-" + index).toString())
                      }
                      handleReactionClick={handleReactionClick}
                      isHighlighted={
                        quoteMessageSearchIdRef
                          ? quoteMessageSearchIdRef.current === chat._id
                          : false
                      }
                      handleReactionUsersList={handleReactionUsersList}
                      isStacked={isStacked}
                      hasName={displayName}
                      hasAction={chat.hasAction}
                      name={chat.name}
                      message={chat.message}
                      // OutgoingMessageTime
                      isRead={chat.isRead}
                      hasBackdrop={chat.hasBackdrop}
                      isEdited={chat.isEdited}
                      time={chat.time}
                      // Reaction
                      hasCounter={chat.hasCounter}
                      hasReactions={chat?.hasReactions}
                      hasReaction2={chat?.hasReaction2}
                      hasReaction3={chat?.hasReaction3}
                      hasReaction4={chat?.hasReaction4}
                      counter={chat.counter}
                      emoji1={chat?.emoji1}
                      emoji2={chat?.emoji2}
                      emoji3={chat?.emoji3}
                      emoji4={chat?.emoji4}
                      showAllEmogis={showAllEmogis}
                      toggleShowEmojis={toggleShowEmojis}
                      initialEmojis={initialEmojis}
                      reactions={reactions}
                      setReactions={setReactions}
                      showEmojis={showEmojis}
                      showDropdown={showDropdown}
                      handleSelectEmoji={handleSelectEmoji}
                      setShowDropdown={setShowDropdown}
                      setShowEmojis={setShowEmojis}
                      reactionMsg={reactionMsg}
                      //  Reply Message type
                      receiverName={chat.receiverName}
                      srcOfReplayMessage={chat.srcOfReplayMessage}
                      altReply={chat.altReply}
                      thumbnail={chat?.videos}
                      MessageReplyType={chat.MessageReplyType}
                      messageToReply={chat.messageToReply}
                      //Dropdown items
                      dropDownOptions={dropDownOptions}
                      onSelectOptions={onSelectOptions}
                      chatData={chat}
                      isInProgress={
                        chat._id && chat._id.slice(0, -1) === "undefined"
                          ? true
                          : false
                      }
                    />
                    {showUnreadMessageDivider && <ChatUnreadDivider />}
                  </InView>
                );
              } else {
                return (
                  <>
                    <ChatMessageReply
                      menuSelectedDivRef={menuSelectedDivRef}
                      messageToReplyArray={chat.messageToReplyArray}
                      messageInArray={chat.messageInArray}
                      uniqueId={chat._id}
                      handleClickOnRepliedMessage={() => {
                        findQuotedmessage(`${chat?.quote_message_id?._id}0`);
                      }}
                      // Reaction
                      hasCounter={chat.hasCounter}
                      hasReactions={chat?.hasReactions}
                      hasReaction2={chat?.hasReaction2}
                      hasReaction3={chat?.hasReaction3}
                      hasReaction4={chat?.hasReaction4}
                      counter={chat.counter}
                      emoji1={chat?.emoji1}
                      emoji2={chat?.emoji2}
                      emoji3={chat?.emoji3}
                      emoji4={chat?.emoji4}
                      showAllEmogis={showAllEmogis}
                      toggleShowEmojis={toggleShowEmojis}
                      initialEmojis={initialEmojis}
                      reactions={reactions}
                      setReactions={setReactions}
                      showEmojis={showEmojis}
                      showDropdown={showDropdown}
                      handleSelectEmoji={handleSelectEmoji}
                      setShowDropdown={setShowDropdown}
                      setShowEmojis={setShowEmojis}
                      reactionMsg={reactionMsg}
                      actionModel={actionModel === chat._id + "-" + index}
                      setActionModel={setActionModel}
                      handleEmojiClick={handleEmojiClick}
                      handleReactionClick={handleReactionClick}
                      handleReactionUsersList={handleReactionUsersList}
                      // Action
                      chatId={chat}
                      type={type}
                      isOutgoing={isOutgoing}
                      AvatarType={chat.AvatarType}
                      Avtarsrc={chat.Avtarsrc}
                      AvtarLetter={chat.AvtarLetter}
                      AvtarIcon={AvtarIcon}
                      handleReplyClick={() => {
                        handleReplyClick(chat);
                      }}
                      handleDotsClick={() =>
                        handleDotsClick((chat._id + "-" + index).toString())
                      }
                      isHighlighted={
                        quoteMessageSearchIdRef
                          ? quoteMessageSearchIdRef.current === chat._id
                          : false
                      }
                      isStacked={isStacked}
                      hasName={displayName}
                      hasAction={chat.hasAction}
                      name={chat.name}
                      message={chat.message}
                      isRead={chat.isRead}
                      hasBackdrop={chat.hasBackdrop}
                      isEdited={chat.isEdited}
                      time={chat.time}
                      receiverName={chat.receiverName}
                      srcOfReplayMessage={chat.srcOfReplayMessage}
                      altReply={chat.altReply}
                      thumbnail={chat?.videos}
                      MessageReplyType={chat.MessageReplyType}
                      messageToReply={chat.messageToReply}
                      //Dropdown items
                      dropDownOptions={dropDownOptions}
                      onSelectOptions={onSelectOptions}
                      chatData={chat}
                      isInProgress={
                        chat._id && chat._id.slice(0, -1) === "undefined"
                          ? true
                          : false
                      }
                    />
                    {showUnreadMessageDivider && <ChatUnreadDivider />}
                  </>
                );
              }
            case "link":
              if (chat?.messageType !== "link") {
                return null; // Ignore rendering if messageType is not "link"
              }
              if (chat?.isLastMessage) {
                return (
                  <InView
                    onChange={(inView, entry) => {
                      if (inView) {
                        handleLoadMoreMessages();
                      }
                    }}
                  >
                    <ChatMessageLink
                      updateMessageMetadata={updateMessageMetadata}
                      menuSelectedDivRef={menuSelectedDivRef}
                      uniqueId={chat._id}
                      messageInArray={chat.messageInArray}
                      // Reaction
                      hasCounter={chat.hasCounter}
                      hasReactions={chat?.hasReactions}
                      hasReaction2={chat?.hasReaction2}
                      hasReaction3={chat?.hasReaction3}
                      hasReaction4={chat?.hasReaction4}
                      counter={chat.counter}
                      emoji1={chat?.emoji1}
                      emoji2={chat?.emoji2}
                      emoji3={chat?.emoji3}
                      emoji4={chat?.emoji4}
                      showAllEmogis={showAllEmogis}
                      toggleShowEmojis={toggleShowEmojis}
                      initialEmojis={initialEmojis}
                      reactions={reactions}
                      setReactions={setReactions}
                      showEmojis={showEmojis}
                      showDropdown={showDropdown}
                      handleSelectEmoji={handleSelectEmoji}
                      setShowDropdown={setShowDropdown}
                      setShowEmojis={setShowEmojis}
                      reactionMsg={reactionMsg}
                      actionModel={actionModel === chat._id + "-" + index}
                      setActionModel={setActionModel}
                      type={type}
                      isOutgoing={isOutgoing}
                      AvatarType={chat.AvatarType}
                      Avtarsrc={chat.Avtarsrc}
                      AvtarLetter={chat.AvtarLetter}
                      AvtarIcon={AvtarIcon}
                      handleEmojiClick={handleEmojiClick}
                      handleReplyClick={() => {
                        handleReplyClick(chat);
                      }}
                      handleDotsClick={() =>
                        handleDotsClick((chat._id + "-" + index).toString())
                      }
                      handleReactionClick={handleReactionClick}
                      isHighlighted={
                        quoteMessageSearchIdRef
                          ? quoteMessageSearchIdRef.current === chat._id
                          : false
                      }
                      handleReactionUsersList={handleReactionUsersList}
                      isStacked={isStacked}
                      hasName={displayName}
                      hasAction={chat.hasAction}
                      name={chat.name}
                      message={chat.message}
                      // OutgoingMessageTime
                      isRead={chat.isRead}
                      hasBackdrop={chat.hasBackdrop}
                      isEdited={chat.isEdited}
                      time={chat.time}
                      // meta detail
                      metaImageSrc={chat?.MetaData?.metaImageSrc}
                      metaTitle={chat?.MetaData?.metaTitle}
                      metaLink={chat?.MetaData?.metaLink}
                      metaDescription={chat?.MetaData?.metaDescription}
                      metaShortDescription={chat?.MetaData?.metaShortDescription}
                      //Dropdown items
                      dropDownOptions={dropDownOptions}
                      onSelectOptions={onSelectOptions}
                      chatData={chat}
                      chatId={chat}
                      isInProgress={
                        chat._id && chat._id.slice(0, -1) === "undefined"
                          ? true
                          : false
                      }
                      messageType={chat?.messageType}
                      frontendUniqueId={chat.frontendUniqueId}
                    />
                    {showUnreadMessageDivider && <ChatUnreadDivider />}
                  </InView>
                );
              } else {
                return (
                  <>
                    <ChatMessageLink
                      frontendUniqueId={chat.frontendUniqueId}
                      updateMessageMetadata={updateMessageMetadata}
                      menuSelectedDivRef={menuSelectedDivRef}
                      uniqueId={chat._id}
                      messageInArray={chat.messageInArray}
                      // Reaction
                      hasCounter={chat.hasCounter}
                      hasReactions={chat?.hasReactions}
                      hasReaction2={chat?.hasReaction2}
                      hasReaction3={chat?.hasReaction3}
                      hasReaction4={chat?.hasReaction4}
                      counter={chat.counter}
                      emoji1={chat?.emoji1}
                      emoji2={chat?.emoji2}
                      emoji3={chat?.emoji3}
                      emoji4={chat?.emoji4}
                      showAllEmogis={showAllEmogis}
                      toggleShowEmojis={toggleShowEmojis}
                      initialEmojis={initialEmojis}
                      reactions={reactions}
                      setReactions={setReactions}
                      showEmojis={showEmojis}
                      showDropdown={showDropdown}
                      handleSelectEmoji={handleSelectEmoji}
                      setShowDropdown={setShowDropdown}
                      setShowEmojis={setShowEmojis}
                      reactionMsg={reactionMsg}
                      actionModel={actionModel === chat._id + "-" + index}
                      setActionModel={setActionModel}
                      type={type}
                      isOutgoing={isOutgoing}
                      AvatarType={chat.AvatarType}
                      Avtarsrc={chat.Avtarsrc}
                      AvtarLetter={chat.AvtarLetter}
                      AvtarIcon={AvtarIcon}
                      handleEmojiClick={handleEmojiClick}
                      handleReplyClick={() => {
                        handleReplyClick(chat);
                      }}
                      handleDotsClick={() =>
                        handleDotsClick((chat._id + "-" + index).toString())
                      }
                      handleReactionClick={handleReactionClick}
                      isHighlighted={
                        quoteMessageSearchIdRef
                          ? quoteMessageSearchIdRef.current === chat._id
                          : false
                      }
                      handleReactionUsersList={handleReactionUsersList}
                      isStacked={isStacked}
                      hasName={displayName}
                      hasAction={chat.hasAction}
                      name={chat.name}
                      message={chat.message}
                      // OutgoingMessageTime
                      isRead={chat.isRead}
                      hasBackdrop={chat.hasBackdrop}
                      isEdited={chat.isEdited}
                      time={chat.time}
                      // meta detail
                      metaImageSrc={chat?.MetaData?.metaImageSrc}
                      metaTitle={chat?.MetaData?.metaTitle}
                      metaLink={chat?.MetaData?.metaLink}
                      metaDescription={chat?.MetaData?.metaDescription}
                      metaShortDescription={chat?.MetaData?.metaShortDescription}
                      //Dropdown items
                      dropDownOptions={dropDownOptions}
                      onSelectOptions={onSelectOptions}
                      chatData={chat}
                      chatId={chat}
                      isInProgress={
                        chat._id && chat._id.slice(0, -1) === "undefined"
                          ? true
                          : false
                      }
                      messageType={chat?.messageType}
                    />
                    {showUnreadMessageDivider && <ChatUnreadDivider />}
                  </>
                );
              }
            case "image":
              if (chat?.isLastMessage) {
                return (
                  <InView
                    onChange={(inView, entry) => {
                      if (inView) {
                        handleLoadMoreMessages();
                      }
                    }}
                  >
                    <ChatMessageImage
                      menuSelectedDivRef={menuSelectedDivRef}
                      uniqueId={chat._id}
                      // Reaction
                      hasCounter={chat.hasCounter}
                      hasReactions={chat?.hasReactions}
                      hasReaction2={chat?.hasReaction2}
                      hasReaction3={chat?.hasReaction3}
                      hasReaction4={chat?.hasReaction4}
                      counter={chat.counter}
                      emoji1={chat?.emoji1}
                      emoji2={chat?.emoji2}
                      emoji3={chat?.emoji3}
                      emoji4={chat?.emoji4}
                      showAllEmogis={showAllEmogis}
                      toggleShowEmojis={toggleShowEmojis}
                      initialEmojis={initialEmojis}
                      reactions={reactions}
                      setReactions={setReactions}
                      showEmojis={showEmojis}
                      showDropdown={showDropdown}
                      handleSelectEmoji={handleSelectEmoji}
                      setShowDropdown={setShowDropdown}
                      setShowEmojis={setShowEmojis}
                      reactionMsg={reactionMsg}
                      actionModel={actionModel === chat._id + "-" + index}
                      setActionModel={setActionModel}
                      type={type}
                      isOutgoing={isOutgoing}
                      AvatarType={chat.AvatarType}
                      Avtarsrc={chat.Avtarsrc}
                      AvtarLetter={chat.AvtarLetter}
                      AvtarIcon={AvtarIcon}
                      handleEmojiClick={handleEmojiClick}
                      handleReplyClick={() => {
                        handleReplyClick(chat);
                      }}
                      handleDotsClick={() =>
                        handleDotsClick((chat._id + "-" + index).toString())
                      }
                      handleReactionClick={handleReactionClick}
                      isHighlighted={
                        quoteMessageSearchIdRef
                          ? quoteMessageSearchIdRef.current === chat._id
                          : false
                      }
                      handleReactionUsersList={handleReactionUsersList}
                      isStacked={isStacked}
                      hasAction={chat.hasAction}
                      // OutgoingMessageTime
                      isRead={chat.isRead}
                      hasBackdrop={chat.hasBackdrop}
                      isEdited={chat.isEdited}
                      time={chat.time}
                      // Image detail
                      src={chat.src}
                      hasTime={chat.hasTime}
                      count={chat.count}
                      //Dropdown items
                      dropDownOptions={dropDownOptions}
                      onSelectOptions={onSelectOptions}
                      chatData={chat}
                      chatId={chat}
                      isInProgress={
                        chat._id && chat._id.slice(0, -1) === "undefined"
                          ? true
                          : false
                      }
                      handlePreviewClick={handlePreviewClick}
                    />
                    {showUnreadMessageDivider && <ChatUnreadDivider />}
                  </InView>
                );
              } else {
                return (
                  <>
                    <ChatMessageImage
                      menuSelectedDivRef={menuSelectedDivRef}
                      uniqueId={chat._id}
                      //Reaction
                      hasCounter={chat.hasCounter}
                      hasReactions={chat?.hasReactions}
                      hasReaction2={chat?.hasReaction2}
                      hasReaction3={chat?.hasReaction3}
                      hasReaction4={chat?.hasReaction4}
                      counter={chat.counter}
                      emoji1={chat?.emoji1}
                      emoji2={chat?.emoji2}
                      emoji3={chat?.emoji3}
                      emoji4={chat?.emoji4}
                      showAllEmogis={showAllEmogis}
                      toggleShowEmojis={toggleShowEmojis}
                      initialEmojis={initialEmojis}
                      reactions={reactions}
                      setReactions={setReactions}
                      showEmojis={showEmojis}
                      showDropdown={showDropdown}
                      handleSelectEmoji={handleSelectEmoji}
                      setShowDropdown={setShowDropdown}
                      setShowEmojis={setShowEmojis}
                      reactionMsg={reactionMsg}
                      actionModel={actionModel === chat._id + "-" + index}
                      setActionModel={setActionModel}
                      type={type}
                      isOutgoing={isOutgoing}
                      AvatarType={chat.AvatarType}
                      Avtarsrc={chat.Avtarsrc}
                      AvtarLetter={chat.AvtarLetter}
                      AvtarIcon={AvtarIcon}
                      handleEmojiClick={handleEmojiClick}
                      handleReplyClick={() => {
                        handleReplyClick(chat);
                      }}
                      handleDotsClick={() =>
                        handleDotsClick((chat._id + "-" + index).toString())
                      }
                      handleReactionClick={handleReactionClick}
                      isHighlighted={
                        quoteMessageSearchIdRef
                          ? quoteMessageSearchIdRef.current === chat._id
                          : false
                      }
                      handleReactionUsersList={handleReactionUsersList}
                      isStacked={isStacked}
                      hasAction={chat.hasAction}
                      // OutgoingMessageTime
                      isRead={chat.isRead}
                      hasBackdrop={chat.hasBackdrop}
                      isEdited={chat.isEdited}
                      time={chat.time}
                      // Reaction

                      // Image detail
                      src={chat.src}
                      hasTime={chat.hasTime}
                      count={chat.count}
                      //Dropdown items
                      dropDownOptions={dropDownOptions}
                      onSelectOptions={onSelectOptions}
                      chatData={chat}
                      chatId={chat}
                      isInProgress={
                        chat._id && chat._id.slice(0, -1) === "undefined"
                          ? true
                          : false
                      }
                      handlePreviewClick={handlePreviewClick}
                    />
                    {showUnreadMessageDivider && <ChatUnreadDivider />}
                  </>
                );
              }
            case "video":
              if (chat?.isLastMessage) {
                return (
                  <InView
                    onChange={(inView, entry) => {
                      if (inView) {
                        handleLoadMoreMessages();
                      }
                    }}
                  >
                    <ChatMessageVideo
                      menuSelectedDivRef={menuSelectedDivRef}
                      uniqueId={chat._id}
                      // Reaction
                      hasCounter={chat.hasCounter}
                      hasReactions={chat?.hasReactions}
                      hasReaction2={chat?.hasReaction2}
                      hasReaction3={chat?.hasReaction3}
                      hasReaction4={chat?.hasReaction4}
                      counter={chat.counter}
                      emoji1={chat?.emoji1}
                      emoji2={chat?.emoji2}
                      emoji3={chat?.emoji3}
                      emoji4={chat?.emoji4}
                      showAllEmogis={showAllEmogis}
                      toggleShowEmojis={toggleShowEmojis}
                      initialEmojis={initialEmojis}
                      reactions={reactions}
                      setReactions={setReactions}
                      showEmojis={showEmojis}
                      showDropdown={showDropdown}
                      handleSelectEmoji={handleSelectEmoji}
                      setShowDropdown={setShowDropdown}
                      setShowEmojis={setShowEmojis}
                      reactionMsg={reactionMsg}
                      actionModel={actionModel === chat._id + "-" + index}
                      setActionModel={setActionModel}
                      type={type}
                      isOutgoing={isOutgoing}
                      AvatarType={chat.AvatarType}
                      Avtarsrc={chat.Avtarsrc}
                      AvtarLetter={chat.AvtarLetter}
                      AvtarIcon={AvtarIcon}
                      handleEmojiClick={handleEmojiClick}
                      handleReplyClick={() => {
                        handleReplyClick(chat);
                      }}
                      handleDotsClick={() =>
                        handleDotsClick((chat._id + "-" + index).toString())
                      }
                      handleReactionClick={handleReactionClick}
                      isHighlighted={
                        quoteMessageSearchIdRef
                          ? quoteMessageSearchIdRef.current === chat._id
                          : false
                      }
                      handleReactionUsersList={handleReactionUsersList}
                      isStacked={isStacked}
                      hasAction={chat.hasAction}
                      // OutgoingMessageTime
                      isRead={chat.isRead}
                      hasBackdrop={chat.hasBackdrop}
                      isEdited={chat.isEdited}
                      time={chat.time}
                      // Video detail
                      src={chat.src}
                      hasTime={chat.hasTime}
                      count={chat.count}
                      videoType={chat.videoType.map((data) => {
                        return data;
                      })}
                      //Dropdown items
                      dropDownOptions={dropDownOptions}
                      onSelectOptions={onSelectOptions}
                      chatData={chat}
                      chatId={chat}
                      isInProgress={
                        chat._id && chat._id.slice(0, -1) === "undefined"
                          ? true
                          : false
                      }
                      handlePreviewClick={handlePreviewClick}
                    />
                    {showUnreadMessageDivider && <ChatUnreadDivider />}
                  </InView>
                );
              } else {
                return (
                  <>
                    <ChatMessageVideo
                      menuSelectedDivRef={menuSelectedDivRef}
                      uniqueId={chat._id}
                      // Reaction
                      hasCounter={chat.hasCounter}
                      hasReactions={chat?.hasReactions}
                      hasReaction2={chat?.hasReaction2}
                      hasReaction3={chat?.hasReaction3}
                      hasReaction4={chat?.hasReaction4}
                      counter={chat.counter}
                      emoji1={chat?.emoji1}
                      emoji2={chat?.emoji2}
                      emoji3={chat?.emoji3}
                      emoji4={chat?.emoji4}
                      showAllEmogis={showAllEmogis}
                      toggleShowEmojis={toggleShowEmojis}
                      initialEmojis={initialEmojis}
                      reactions={reactions}
                      setReactions={setReactions}
                      showEmojis={showEmojis}
                      showDropdown={showDropdown}
                      handleSelectEmoji={handleSelectEmoji}
                      setShowDropdown={setShowDropdown}
                      setShowEmojis={setShowEmojis}
                      reactionMsg={reactionMsg}
                      actionModel={actionModel === chat._id + "-" + index}
                      setActionModel={setActionModel}
                      type={type}
                      isOutgoing={isOutgoing}
                      AvatarType={chat.AvatarType}
                      Avtarsrc={chat.Avtarsrc}
                      AvtarLetter={chat.AvtarLetter}
                      AvtarIcon={AvtarIcon}
                      handleEmojiClick={handleEmojiClick}
                      handleReplyClick={() => {
                        handleReplyClick(chat);
                      }}
                      handleDotsClick={() =>
                        handleDotsClick((chat._id + "-" + index).toString())
                      }
                      handleReactionClick={handleReactionClick}
                      isHighlighted={
                        quoteMessageSearchIdRef
                          ? quoteMessageSearchIdRef.current === chat._id
                          : false
                      }
                      handleReactionUsersList={handleReactionUsersList}
                      isStacked={isStacked}
                      hasAction={chat.hasAction}
                      // OutgoingMessageTime
                      isRead={chat.isRead}
                      hasBackdrop={chat.hasBackdrop}
                      isEdited={chat.isEdited}
                      time={chat.time}
                      // Video detail
                      src={chat.src}
                      hasTime={chat.hasTime}
                      count={chat.count}
                      videoType={chat?.videoType[0]}
                      //Dropdown items
                      dropDownOptions={dropDownOptions}
                      onSelectOptions={onSelectOptions}
                      chatData={chat}
                      chatId={chat}
                      isInProgress={
                        chat._id && chat._id.slice(0, -1) === "undefined"
                          ? true
                          : false
                      }
                      handlePreviewClick={handlePreviewClick}
                    />
                    {showUnreadMessageDivider && <ChatUnreadDivider />}
                  </>
                );
              }
            case "file":
              if (chat?.isLastMessage) {
                return (
                  <InView
                    onChange={(inView, entry) => {
                      if (inView) {
                        handleLoadMoreMessages();
                      }
                    }}
                  >
                    <ChatMessagesFile
                      menuSelectedDivRef={menuSelectedDivRef}
                      uniqueId={chat._id}
                      //Reactoin
                      hasCounter={chat.hasCounter}
                      hasReactions={chat?.hasReactions}
                      hasReaction2={chat?.hasReaction2}
                      hasReaction3={chat?.hasReaction3}
                      hasReaction4={chat?.hasReaction4}
                      counter={chat.counter}
                      emoji1={chat?.emoji1}
                      emoji2={chat?.emoji2}
                      emoji3={chat?.emoji3}
                      emoji4={chat?.emoji4}
                      showAllEmogis={showAllEmogis}
                      toggleShowEmojis={toggleShowEmojis}
                      initialEmojis={initialEmojis}
                      reactions={reactions}
                      setReactions={setReactions}
                      showEmojis={showEmojis}
                      showDropdown={showDropdown}
                      handleSelectEmoji={handleSelectEmoji}
                      setShowDropdown={setShowDropdown}
                      setShowEmojis={setShowEmojis}
                      reactionMsg={reactionMsg}
                      actionModel={actionModel === chat._id + "-" + index}
                      setActionModel={setActionModel}
                      type={type}
                      isOutgoing={isOutgoing}
                      AvatarType={chat.AvatarType}
                      Avtarsrc={chat.Avtarsrc}
                      AvtarLetter={chat.AvtarLetter}
                      AvtarIcon={AvtarIcon}
                      handleEmojiClick={handleEmojiClick}
                      handleReplyClick={() => {
                        handleReplyClick(chat);
                      }}
                      handleDotsClick={() =>
                        handleDotsClick((chat._id + "-" + index).toString())
                      }
                      handleReactionClick={handleReactionClick}
                      isHighlighted={
                        quoteMessageSearchIdRef
                          ? quoteMessageSearchIdRef.current === chat._id
                          : false
                      }
                      handleReactionUsersList={handleReactionUsersList}
                      isStacked={isStacked}
                      // OutgoingMessageTime
                      isRead={chat.isRead}
                      hasBackdrop={chat.hasBackdrop}
                      isEdited={chat.isEdited}
                      time={chat.time}
                      // File detail
                      files={chat.files}
                      //Dropdown items
                      dropDownOptions={dropDownOptions}
                      onSelectOptions={onSelectOptions}
                      chatData={chat}
                      chatId={chat}
                      isInProgress={
                        chat._id && chat._id.slice(0, -1) === "undefined"
                          ? true
                          : false
                      }
                      handlePreviewClick={handlePreviewClick}
                    />
                    {showUnreadMessageDivider && <ChatUnreadDivider />}
                  </InView>
                );
              } else {
                return (
                  <>
                    <ChatMessagesFile
                      menuSelectedDivRef={menuSelectedDivRef}
                      uniqueId={chat._id}
                      // Reaction
                      hasCounter={chat.hasCounter}
                      hasReactions={chat?.hasReactions}
                      hasReaction2={chat?.hasReaction2}
                      hasReaction3={chat?.hasReaction3}
                      hasReaction4={chat?.hasReaction4}
                      counter={chat.counter}
                      emoji1={chat?.emoji1}
                      emoji2={chat?.emoji2}
                      emoji3={chat?.emoji3}
                      emoji4={chat?.emoji4}
                      showAllEmogis={showAllEmogis}
                      toggleShowEmojis={toggleShowEmojis}
                      initialEmojis={initialEmojis}
                      reactions={reactions}
                      setReactions={setReactions}
                      showEmojis={showEmojis}
                      showDropdown={showDropdown}
                      handleSelectEmoji={handleSelectEmoji}
                      setShowDropdown={setShowDropdown}
                      setShowEmojis={setShowEmojis}
                      reactionMsg={reactionMsg}
                      actionModel={actionModel === chat._id + "-" + index}
                      setActionModel={setActionModel}
                      type={type}
                      isOutgoing={isOutgoing}
                      AvatarType={chat.AvatarType}
                      Avtarsrc={chat.Avtarsrc}
                      AvtarLetter={chat.AvtarLetter}
                      AvtarIcon={AvtarIcon}
                      handleEmojiClick={handleEmojiClick}
                      handleReplyClick={() => {
                        handleReplyClick(chat);
                      }}
                      handleDotsClick={() =>
                        handleDotsClick((chat._id + "-" + index).toString())
                      }
                      handleReactionClick={handleReactionClick}
                      isHighlighted={
                        quoteMessageSearchIdRef
                          ? quoteMessageSearchIdRef.current === chat._id
                          : false
                      }
                      handleReactionUsersList={handleReactionUsersList}
                      isStacked={isStacked}
                      hasAction={chat.hasAction}
                      // OutgoingMessageTime
                      isRead={chat.isRead}
                      hasBackdrop={chat.hasBackdrop}
                      isEdited={chat.isEdited}
                      time={chat.time}
                      // File detail
                      files={chat.files}
                      //Dropdown items
                      dropDownOptions={dropDownOptions}
                      onSelectOptions={onSelectOptions}
                      chatData={chat}
                      chatId={chat}
                      isInProgress={
                        chat._id && chat._id.slice(0, -1) === "undefined"
                          ? true
                          : false
                      }
                      handlePreviewClick={handlePreviewClick}
                    />
                    {showUnreadMessageDivider && <ChatUnreadDivider />}
                  </>
                );
              }
            case "voice":
              if (chat?.isLastMessage) {
                return (
                  <InView
                    onChange={(inView, entry) => {
                      if (inView) {
                        handleLoadMoreMessages();
                      }
                    }}
                  >
                    <ChatMessagesVoiceMessage
                      menuSelectedDivRef={menuSelectedDivRef}
                      // Reaction
                      hasCounter={chat.hasCounter}
                      hasReactions={chat?.hasReactions}
                      hasReaction2={chat?.hasReaction2}
                      hasReaction3={chat?.hasReaction3}
                      hasReaction4={chat?.hasReaction4}
                      counter={chat.counter}
                      emoji1={chat?.emoji1}
                      emoji2={chat?.emoji2}
                      emoji3={chat?.emoji3}
                      emoji4={chat?.emoji4}
                      showAllEmogis={showAllEmogis}
                      toggleShowEmojis={toggleShowEmojis}
                      initialEmojis={initialEmojis}
                      reactions={reactions}
                      setReactions={setReactions}
                      showEmojis={showEmojis}
                      showDropdown={showDropdown}
                      handleSelectEmoji={handleSelectEmoji}
                      setShowDropdown={setShowDropdown}
                      setShowEmojis={setShowEmojis}
                      reactionMsg={reactionMsg}
                      actionModel={actionModel === chat._id + "-" + index}
                      setActionModel={setActionModel}
                      type={type}
                      isOutgoing={isOutgoing}
                      AvatarType={chat.AvatarType}
                      Avtarsrc={chat.Avtarsrc}
                      AvtarLetter={chat.AvtarLetter}
                      AvtarIcon={AvtarIcon}
                      handleEmojiClick={handleEmojiClick}
                      handleReplyClick={() => {
                        handleReplyClick(chat);
                      }}
                      handleDotsClick={() =>
                        handleDotsClick((chat._id + "-" + index).toString())
                      }
                      handleReactionClick={handleReactionClick}
                      isHighlighted={
                        quoteMessageSearchIdRef
                          ? quoteMessageSearchIdRef.current === chat._id
                          : false
                      }
                      handleReactionUsersList={handleReactionUsersList}
                      isStacked={isStacked}
                      hasAction={chat.hasAction}
                      // OutgoingMessageTime
                      isRead={chat.isRead}
                      hasBackdrop={chat.hasBackdrop}
                      isEdited={chat.isEdited}
                      time={chat.time}
                      // Reaction
                      // Voice detail
                      voiceSrc={chat.voiceSrc}
                      uniqueId={chat._id}
                      //Dropdown items
                      dropDownOptions={dropDownOptions}
                      onSelectOptions={onSelectOptions}
                      chatData={chat}
                      chatId={chat}
                      isInProgress={
                        chat._id && chat._id.slice(0, -1) === "undefined"
                          ? true
                          : false
                      }
                    />
                    {showUnreadMessageDivider && <ChatUnreadDivider />}
                  </InView>
                );
              } else {
                return (
                  <>
                    <ChatMessagesVoiceMessage
                      menuSelectedDivRef={menuSelectedDivRef}
                      // Reaction
                      hasCounter={chat.hasCounter}
                      hasReactions={chat?.hasReactions}
                      hasReaction2={chat?.hasReaction2}
                      hasReaction3={chat?.hasReaction3}
                      hasReaction4={chat?.hasReaction4}
                      counter={chat.counter}
                      emoji1={chat?.emoji1}
                      emoji2={chat?.emoji2}
                      emoji3={chat?.emoji3}
                      emoji4={chat?.emoji4}
                      showAllEmogis={showAllEmogis}
                      toggleShowEmojis={toggleShowEmojis}
                      initialEmojis={initialEmojis}
                      reactions={reactions}
                      setReactions={setReactions}
                      showEmojis={showEmojis}
                      showDropdown={showDropdown}
                      handleSelectEmoji={handleSelectEmoji}
                      setShowDropdown={setShowDropdown}
                      setShowEmojis={setShowEmojis}
                      reactionMsg={reactionMsg}
                      actionModel={actionModel === chat._id + "-" + index}
                      setActionModel={setActionModel}
                      type={type}
                      isOutgoing={isOutgoing}
                      AvatarType={chat.AvatarType}
                      Avtarsrc={chat.Avtarsrc}
                      AvtarLetter={chat.AvtarLetter}
                      AvtarIcon={AvtarIcon}
                      handleEmojiClick={handleEmojiClick}
                      handleReplyClick={() => {
                        handleReplyClick(chat);
                      }}
                      handleDotsClick={() =>
                        handleDotsClick((chat._id + "-" + index).toString())
                      }
                      handleReactionClick={handleReactionClick}
                      isHighlighted={
                        quoteMessageSearchIdRef
                          ? quoteMessageSearchIdRef.current === chat._id
                          : false
                      }
                      handleReactionUsersList={handleReactionUsersList}
                      isStacked={isStacked}
                      hasAction={chat.hasAction}
                      // OutgoingMessageTime
                      isRead={chat.isRead}
                      hasBackdrop={chat.hasBackdrop}
                      isEdited={chat.isEdited}
                      time={chat.time}
                      // Voice detail
                      voiceSrc={chat.voiceSrc}
                      uniqueId={chat._id}
                      //Dropdown items
                      dropDownOptions={dropDownOptions}
                      onSelectOptions={onSelectOptions}
                      chatData={chat}
                      chatId={chat}
                      isInProgress={
                        chat._id && chat._id.slice(0, -1) === "undefined"
                          ? true
                          : false
                      }
                    />
                    {showUnreadMessageDivider && <ChatUnreadDivider />}
                  </>
                );
              }
            case "emoji":
              if (chat?.isLastMessage) {
                return (
                  <InView
                    onChange={(inView, entry) => {
                      if (inView) {
                        handleLoadMoreMessages();
                      }
                    }}
                  >
                    <ChatMessagesEmoji
                      menuSelectedDivRef={menuSelectedDivRef}
                      uniqueId={chat._id}
                      // Reaction
                      hasCounter={chat.hasCounter}
                      hasReactions={chat?.hasReactions}
                      hasReaction2={chat?.hasReaction2}
                      hasReaction3={chat?.hasReaction3}
                      hasReaction4={chat?.hasReaction4}
                      counter={chat.counter}
                      emoji1={chat?.emoji1}
                      emoji2={chat?.emoji2}
                      emoji3={chat?.emoji3}
                      emoji4={chat?.emoji4}
                      showAllEmogis={showAllEmogis}
                      toggleShowEmojis={toggleShowEmojis}
                      initialEmojis={initialEmojis}
                      reactions={reactions}
                      setReactions={setReactions}
                      showEmojis={showEmojis}
                      showDropdown={showDropdown}
                      handleSelectEmoji={handleSelectEmoji}
                      setShowDropdown={setShowDropdown}
                      setShowEmojis={setShowEmojis}
                      reactionMsg={reactionMsg}
                      actionModel={actionModel === chat._id + "-" + index}
                      setActionModel={setActionModel}
                      type={type}
                      isOutgoing={isOutgoing}
                      AvatarType={chat.AvatarType}
                      Avtarsrc={chat.Avtarsrc}
                      AvtarLetter={chat.AvtarLetter}
                      AvtarIcon={AvtarIcon}
                      handleEmojiClick={handleEmojiClick}
                      handleReplyClick={() => {
                        handleReplyClick(chat);
                      }}
                      handleDotsClick={() =>
                        handleDotsClick((chat._id + "-" + index).toString())
                      }
                      handleReactionClick={handleReactionClick}
                      isHighlighted={
                        quoteMessageSearchIdRef
                          ? quoteMessageSearchIdRef.current === chat._id
                          : false
                      }
                      handleReactionUsersList={handleReactionUsersList}
                      isStacked={isStacked}
                      hasAction={chat.hasAction}
                      message={chat.message}
                      // Emojis
                      //Dropdown items
                      dropDownOptions={dropDownOptions}
                      onSelectOptions={onSelectOptions}
                      chatData={chat}
                      chatId={chat}
                      isInProgress={
                        chat._id && chat._id.slice(0, -1) === "undefined"
                          ? true
                          : false
                      }
                    />
                    {showUnreadMessageDivider && <ChatUnreadDivider />}
                  </InView>
                );
              } else {
                return (
                  <>
                    <ChatMessagesEmoji
                      menuSelectedDivRef={menuSelectedDivRef}
                      uniqueId={chat._id}
                      // Reaction
                      hasCounter={chat.hasCounter}
                      hasReactions={chat?.hasReactions}
                      hasReaction2={chat?.hasReaction2}
                      hasReaction3={chat?.hasReaction3}
                      hasReaction4={chat?.hasReaction4}
                      counter={chat.counter}
                      emoji1={chat?.emoji1}
                      emoji2={chat?.emoji2}
                      emoji3={chat?.emoji3}
                      emoji4={chat?.emoji4}
                      showAllEmogis={showAllEmogis}
                      toggleShowEmojis={toggleShowEmojis}
                      initialEmojis={initialEmojis}
                      reactions={reactions}
                      setReactions={setReactions}
                      showEmojis={showEmojis}
                      showDropdown={showDropdown}
                      handleSelectEmoji={handleSelectEmoji}
                      setShowDropdown={setShowDropdown}
                      setShowEmojis={setShowEmojis}
                      reactionMsg={reactionMsg}
                      actionModel={actionModel === chat._id + "-" + index}
                      setActionModel={setActionModel}
                      type={type}
                      isOutgoing={isOutgoing}
                      AvatarType={chat.AvatarType}
                      Avtarsrc={chat.Avtarsrc}
                      AvtarLetter={chat.AvtarLetter}
                      AvtarIcon={AvtarIcon}
                      handleEmojiClick={handleEmojiClick}
                      handleReplyClick={() => {
                        handleReplyClick(chat);
                      }}
                      handleDotsClick={() =>
                        handleDotsClick((chat._id + "-" + index).toString())
                      }
                      handleReactionClick={handleReactionClick}
                      isHighlighted={
                        quoteMessageSearchIdRef
                          ? quoteMessageSearchIdRef.current === chat._id
                          : false
                      }
                      handleReactionUsersList={handleReactionUsersList}
                      isStacked={isStacked}
                      hasAction={chat.hasAction}
                      message={chat.message}
                      // Emojis
                      //Dropdown items
                      dropDownOptions={dropDownOptions}
                      onSelectOptions={onSelectOptions}
                      chatData={chat}
                      chatId={chat}
                      isInProgress={
                        chat._id && chat._id.slice(0, -1) === "undefined"
                          ? true
                          : false
                      }
                    />
                    {showUnreadMessageDivider && <ChatUnreadDivider />}
                  </>
                );
              }
            case "processing":
              if (chat?.isLastMessage) {
                return (
                  <InView
                    onChange={(inView, entry) => {
                      if (inView) {
                        handleLoadMoreMessages();
                      }
                    }}
                  >
                    <CompressionProgress
                      time={chat.time}
                      isStacked={isStacked}
                      Text={chat.progressStatus}
                    />
                  </InView>
                );
              } else {
                return (
                  <>
                    <CompressionProgress
                      time={chat.time}
                      isStacked={isStacked}
                      Text={chat.progressStatus}
                    />
                  </>
                );
              }
            default:
              return null;
              break;

          }
        })}
    </>
  );
};

StackedMessagesContainer.propTypes = {
  unreadMessageIndex: PropTypes.string,
  type: PropTypes.oneOf(["Channel/Group", "Personal"]),
  isOutgoing: PropTypes.bool,
  actionModel: PropTypes.string,
  setActionModel: PropTypes.func,
  AvatarType: PropTypes.oneOf(["photo", "icon", "letter", "logo", "mds"]),
  Avtarsrc: PropTypes.string,
  AvtarLetter: PropTypes.string,
  AvtarIcon: PropTypes.element,
  handleReplyClick: PropTypes.func,
  handleDotsClick: PropTypes.func,
  handleReactionClick: PropTypes.func,
  hasCounter: PropTypes.bool,
  hasReaction2: PropTypes.bool,
  hasReaction3: PropTypes.bool,
  hasReaction4: PropTypes.bool,
  emoji1: PropTypes.string,
  emoji2: PropTypes.string,
  emoji3: PropTypes.string,
  emoji4: PropTypes.string,
  counter: PropTypes.number,

  handleEmojiClick: PropTypes.func,
  showAllEmogis: PropTypes.bool,
  toggleShowEmojis: PropTypes.func,
  initialEmojis: PropTypes.array,
  reactions: PropTypes.array,
  setReactions: PropTypes.func,
  showEmojis: PropTypes.bool,
  setShowEmojis: PropTypes.bool,
  showDropdown: PropTypes.bool,
  handleSelectEmoji: PropTypes.func,
  setShowDropdown: PropTypes.func,
  reactionMsg: PropTypes.string,
  chatData: PropTypes.array,
  dropDownOptions: PropTypes.array,
  onSelectOptions: PropTypes.func,
  handleReactionUsersList: PropTypes.func,
  hoverEffects: PropTypes.bool,
  setHoverEffects: PropTypes.func,
};

export default StackedMessagesContainer;
