const allchatlist = [];

const ReduAllChatList = (state = allchatlist, action) => {
  switch (action.type) {
    case "FETCHALLCHATLIST":
      if (action.payload && action.payload.length > 0) {
        let arr = [...action.payload].sort((a, b) => {
          if (b.userTimeStamp && a.userTimeStamp) {
            return b.userTimeStamp.localeCompare(a.userTimeStamp);
          } else if (a.userTimeStamp) return a;
          else if (b.userTimeStamp) return b;
          else return a;
        });
        arr.forEach((item, index, array) => {
          let type = item.type === "airtable-syncs" ? "user" : item.type;
          if (item.receiverId) {
            //fisrt name
            let firstname = "",
              senderFirstName = "";
            let image = "",
              senderImage = "";
            senderImage = item?.senderId?.profileImg ?? "";
            let senderFirstnameUser = item?.senderId?.first_name
              ? item?.senderId?.first_name
              : "";
            let senderLastnameUser = item?.senderId?.last_name
              ? item.senderId.last_name
              : "";
            senderFirstName = senderFirstnameUser.length
              ? senderFirstnameUser + " " + senderLastnameUser
              : item?.senderId?.attendeeDetail?.name;
            if (type === "user") {
              image = item?.receiverId?.profileImg ?? "";
              let firstnameUser = item?.receiverId?.first_name
                ? item?.receiverId?.first_name
                : "";
              let lastnameUser = item?.receiverId?.last_name
                ? item?.receiverId?.last_name
                : "";
              firstname =
                item?.receiverId?.last_name || item?.receiverId?.first_name
                  ? firstnameUser
                    ? firstnameUser + " " + lastnameUser
                    : item?.receiverId?.attendeeDetail?.name
                  : item?.receiverId?.attendeeDetail?.name;
            } else if (type === "userChatGroup") {
              firstname = item?.receiverId?.groupTitle ?? "";
              image = item?.receiverId?.groupImage ?? "";
            }
            //media-otherfiles logic
            let messageType = item.messageType;
            let image_video = [];
            let otherfiles = [];
            let message = item?.lastMessage ?? "";
            if (messageType === "media") {
              message = "";
              image_video.push(item?.lastMessage ?? "");
            } else if (messageType === "file") {
              otherfiles.push(item?.lastMessage ?? "");
              message = "";
            }
            //assigning the value
            array[index] = {
              ...item,
              id: item?.receiverId ? item?.receiverId?._id : item?.receiverId,
              type,
              firstname,
              image,
              message,
              unreadMsg: item?.count ?? null,
              useronline: item?.offlineOnline ?? "",
              image_video,
              otherfiles,
              sender: {
                id: item?.senderId?._id,
                firstname: senderFirstName,
                image: senderImage,
              },
            };
          }
        });
        return arr;
      } else {
        return state;
      }
    case "ADDNEWCHATLIST":
      // Called when loading more data
      if (state !== undefined && action.payload && action.payload.length > 0) {
        let arr = [...action.payload].sort((a, b) => {
          if (b.userTimeStamp && a.userTimeStamp) {
            return b.userTimeStamp.localeCompare(a.userTimeStamp);
          } else if (a.userTimeStamp) return a;
          else if (b.userTimeStamp) return b;
          else return a;
        });

        arr.forEach((item, index, array) => {
          let type = item.type === "airtable-syncs" ? "user" : item.type;
          if (item.receiverId) {
            // Process the same fields as in the first case
            let firstname = "",
              senderFirstName = "";
            let image = "",
              senderImage = "";
            senderImage = item?.senderId?.profileImg ?? "";
            let senderFirstnameUser = item?.senderId?.first_name
              ? item?.senderId?.first_name
              : "";
            let senderLastnameUser = item?.senderId?.last_name
              ? item.senderId.last_name
              : "";
            senderFirstName = senderFirstnameUser.length
              ? senderFirstnameUser + " " + senderLastnameUser
              : item?.senderId?.attendeeDetail?.name;

            if (type === "user") {
              image = item?.receiverId?.profileImg ?? "";
              let firstnameUser = item?.receiverId?.first_name
                ? item?.receiverId?.first_name
                : "";
              let lastnameUser = item?.receiverId?.last_name
                ? item?.receiverId?.last_name
                : "";
              firstname =
                item?.receiverId?.last_name || item?.receiverId?.first_name
                  ? firstnameUser
                    ? firstnameUser + " " + lastnameUser
                    : item?.receiverId?.attendeeDetail?.name
                  : item?.receiverId?.attendeeDetail?.name;
            } else if (type === "userChatGroup") {
              firstname = item?.receiverId?.groupTitle ?? "";
              image = item?.receiverId?.groupImage ?? "";
            }

            // Media and file handling
            let messageType = item.messageType;
            let image_video = [];
            let otherfiles = [];
            let message = item?.lastMessage ?? "";
            if (messageType === "media") {
              message = "";
              image_video.push(item?.lastMessage ?? "");
            } else if (messageType === "file") {
              otherfiles.push(item?.lastMessage ?? "");
              message = "";
            }

            // Assigning the new data
            array[index] = {
              ...item,
              id: item?.receiverId ? item?.receiverId?._id : item?.receiverId,
              type,
              firstname,
              image,
              message,
              unreadMsg: item?.count ?? null,
              useronline: item?.offlineOnline ?? "",
              image_video,
              otherfiles,
              sender: {
                id: item?.senderId?._id,
                firstname: senderFirstName,
                image: senderImage,
              },
            };
          }
        });

        // Append the new data to the existing state
        return [...state, ...arr];
      } else {
        return state;
      }
    case "CLEARCHATFROMLIST":
      let arr = [
        ...state.map((chatlist) => {
          if (chatlist.id === action.payload)
            return {
              ...chatlist,
              message: "",
              image: "",
              time: "",
              image_video: [],
              otherfiles: [],
              unreadMsg: 0,
              isMention: false,
              activity_status: false,
              activity: {},
            };
          else return chatlist;
        }),
      ];
      arr.sort(function (a, b) {
        if (b.userTimeStamp && a.userTimeStamp) {
          return b.userTimeStamp.localeCompare(a.userTimeStamp);
        } else return a;
      });
      return arr;
    case "SETLASTMESSAGE":
      let senderName = action.payload.message.sender?.firstname;
      let senderImg = action.payload.message.sender?.image;
      let formattedLastMessage = {
        id:
          action.payload?.message?.recipient?.id !==
            localStorage.getItem("userid")
            ? action.payload?.message?.recipient?.id
            : action.payload?.message?.sender?.id,
        messageType: action.payload.message.message_type,
        lastMessage: action.payload.message.message,
        firstname:
          action.payload?.message?.recipient?.id !==
            localStorage.getItem("userid")
            ? action.payload.message.recipient.firstname
            : action.payload.message.sender?.firstname,
        message: action.payload.message.message,
        userTimeStamp: action.payload.message.userTimeStamp,
        taggedUserId: action.payload.message.taggedUserId,
        type: action.payload.message.type,
        count: action.payload.currentPage
          ? 0
          : action.payload.message.sender.id !== localStorage.getItem("userid")
            ? 1
            : 0,
        unreadMsg: action.payload.currentPage
          ? 0
          : action.payload.message.sender.id !== localStorage.getItem("userid")
            ? 1
            : 0,
        useronline: true,
        offlineOnline: true,
        sender: {
          id: action.payload.message?.sender?.id,
          firstname: senderName,
          image: senderImg,
        },
        activity_status: action.payload.message?.activity_status,
        activity: action.payload.message?.activity,
        isMention: action.payload.message?.taggedUserId?.filter(
          (user) => user._id === localStorage.getItem("userId")
        ).length
          ? true
          : false,
      };
      let arrary = [...state];
      let isExistingChat = false;
      if (formattedLastMessage) {
        arrary = arrary.map((item) => {
          if (item.id === formattedLastMessage.id) {
            isExistingChat = true;

            return {
              ...item,
              ...formattedLastMessage,
              count: action.payload.currentPage
                ? 0
                : action.payload.message.sender.id !==
                  localStorage.getItem("userid")
                  ? item.count
                    ? item.count + 1
                    : 1
                  : item.count,
              unreadMsg: action.payload.currentPage
                ? 0
                : action.payload.message.sender.id !==
                  localStorage.getItem("userid")
                  ? item.unreadMsg + 1
                  : item.unreadMsg,
              isMention: formattedLastMessage.isMention ?? item.isMention,
            };
            // return getStructuredFormat(formattedLastMessage);
          } else {
            return item;
          }
        });
        if (!isExistingChat) {
          arrary.push(formattedLastMessage);
        }
        arrary.sort((a, b) => {
          if (b.userTimeStamp && a.userTimeStamp) {
            return b.userTimeStamp.localeCompare(a.userTimeStamp);
          } else return a;
        });
      }
      return arrary;
    case "SETLASTMESSAGEOFCHAT":
      let item = action.payload;
      let type = item.type === "airtable-syncs" ? "user" : item.type;
      //fisrt name
      let firstname = "",
        senderFirstName = "";
      let image = "",
        senderImage = "";
      senderImage = item?.senderId?.profileImg ?? "";
      let senderFirstnameUser = item?.senderId?.first_name
        ? item?.senderId?.first_name
        : "";
      let senderLastnameUser = item?.senderId?.last_name
        ? item?.senderId?.last_name
        : "";
      senderFirstName = senderFirstnameUser.length
        ? senderFirstnameUser + " " + senderLastnameUser
        : item?.senderId?.attendeeDetail?.name;
      if (type === "user") {
        image = item?.receiverId?.profileImg ?? "";
        let firstnameUser = item?.receiverId?.first_name
          ? item?.receiverId?.first_name
          : "";
        let lastnameUser = item?.receiverId?.last_name
          ? item?.receiverId?.last_name
          : "";
        firstname =
          item?.receiverId?.first_name || item?.receiverId?.last_name
            ? firstnameUser + " " + lastnameUser
            : item?.receiverId?.attendeeDetail?.name;
      } else if (type === "userChatGroup") {
        firstname = item?.receiverId?.groupTitle ?? "";
        image = item?.receiverId?.groupImage ?? "";
      }
      //media-otherfiles logic
      let messageType = item.messageType;
      let image_video = [];
      let otherfiles = [];
      let message = item?.lastMessage ?? "";
      if (messageType === "media") {
        message = "";
        image_video.push(item?.lastMessage ?? "");
      } else if (messageType === "file") {
        otherfiles.push(item?.lastMessage ?? "");
        message = "";
      }
      //assigning the value
      let formattedData = {
        ...item,
        id: item.receiverId ? item.receiverId._id : item.receiverId,
        type,
        firstname,
        image,
        message,
        unreadMsg: item?.count ?? null,
        useronline: item?.offlineOnline ?? "",
        image_video,
        otherfiles,
        sender: {
          id: item?.senderId?._id,
          firstname: senderFirstName,
          image: senderImage,
        },
        activity_status: item?.activity_status,
        activity: item?.activity,
        isMention: item?.isMention,
        count: item?.count,
      };

      let chatListArray = [];
      for (let index = 0; index < state.length; index++) {
        if (state[index].id === formattedData.id) {
          chatListArray.push(formattedData);
        } else {
          chatListArray.push(state[index]);
        }
      }
      chatListArray.sort((a, b) => {
        if (b.userTimeStamp && a.userTimeStamp) {
          return b.userTimeStamp.localeCompare(a.userTimeStamp);
        } else return a;
      });
      return chatListArray;
    case "READMESSAGE":
      let list = state.map((data) => {
        if (data.id === action.payload) {
          return { ...data, count: 0, unreadMsg: 0, isMention: false };
        } else return data;
      });
      return list;
    case "USERONLINE":
      let chatlistonline = state.map((data) => {
        if (data.id === action.payload) {
          return { ...data, offlineOnline: true, useronline: true };
        } else return data;
      });
      return chatlistonline;
    case "USEROFFLINE":
      let chatlistoffline = state.map((data) => {
        if (data.id === action.payload) {
          return { ...data, offlineOnline: false, useronline: false };
        } else return data;
      });
      return chatlistoffline;
    case "DELETECHATFROMLIST":
      let delarr = state.filter((chatlist) => {
        if (chatlist.id !== action.payload) return chatlist;
      });
      delarr.sort(function (a, b) {
        if (b.userTimeStamp && a.userTimeStamp) {
          return b.userTimeStamp.localeCompare(a.userTimeStamp);
        } else return a;
      });
      return delarr;
    case "EDITMESSAGECHATLIST":
      return state.map((msg) => {
        if (msg.id === action.payload.id) {
          return {
            ...msg,
            lastMessage: action.payload.message,
            taggedUserId: action.payload.taggedUserId,
          };
        } else {
          return msg;
        }
      });
    case "UPDATESTARCHAT": 
      const { id } = action.payload;

      // Find the index of the chat to update
      const chatIndex = state.findIndex((chat) => chat.id === id);
      if (chatIndex !== -1) {
        const currentChat = state[chatIndex];
        const isCurrentlyStarred = currentChat.isStar;
        let updatedState = [...state];

        const updatedChat = {
          ...currentChat,
          isStar: !isCurrentlyStarred,
        };

        updatedState.splice(chatIndex, 1);

        if (updatedChat.isStar) {
          updatedState.unshift(updatedChat);
        } else {
          const timestampToCompare = updatedChat.userTimeStamp;
          const insertIndex = updatedState.findIndex((chat) => {
            return (
              chat.userTimeStamp &&
              new Date(chat.userTimeStamp) < new Date(timestampToCompare)
            );
          });
          if (insertIndex !== -1) {
            updatedState.splice(insertIndex, 0, updatedChat);
          }
        }
        // Return the updated and sorted state
        return updatedState.sort((a, b) => {
          // Sort by isStar status first
          if (a.isStar && !b.isStar) return -1; // a comes first if it's starred
          if (!a.isStar && b.isStar) return 1; // b comes first if it's starred

          // Then sort by userTimeStamp if both are starred or unstarred
          const dateA = a.userTimeStamp
            ? new Date(a.userTimeStamp)
            : new Date(0);
          const dateB = b.userTimeStamp
            ? new Date(b.userTimeStamp)
            : new Date(0);

          return dateB - dateA; // Sort in descending order
        });
      } else {
        // If the chat does not exist in the state, return the state unchanged.
        return state;
      }
    case "UPDATEMUTECHAT":
      return state.map((chat) => {
        if (chat.id === action.payload.id) {
          // Update the chat with new star status or any other properties.
          return {
            ...chat,
            isMuted: !chat.isMuted, // assuming payload contains new starred status
            // Add more properties to update here if needed
          };
        }
        return chat;
      });
    case "LOGOUT":
      return allchatlist;
    default:
      return state;
  }
};

export default ReduAllChatList;
