import moment from "moment";
import { formatBytes } from "./ConvertBytes";
import { replaceIdWithNameUsingUnformattedList } from "./taggedUserIdReplace";
import { checkEmojiMessage } from "./CheckEmoji";

const formatMessage = (
  message,
  messageIndex,
  isLastMessage,
  isQuoteMessage = false
) => {
  message = { ...message, isLastMessage: isLastMessage };
  if (message?.activity_status) {
    switch (message?.activity.type) {
      // -Please not Remove this commented code this code is future use when client revert to channel Activity
      // case "createdChannel":
      //   return {
      //     ...message,
      //     _id: `${message._id}${messageIndex}`,
      //     messageType: "activity",
      //     activity_status: message.activity_status,
      //     variant: "ChannelCreated",
      //     name: "Admin",
      //     channelName: message.activity.newGroupName,
      //   };
      case "created":
        return [
          {
            ...message,
            _id: `${message._id}${messageIndex}`,
            messageType: "activity",
            activity_status: message.activity_status,
            variant: "GroupLimit",
            memberCount: 50,
          },
          {
            ...message,
            _id: `${message._id}${messageIndex}`,
            messageType: "activity",
            activity_status: message.activity_status,
            variant: "GroupCreated",
            name: message.activity.adminId
              ? message.activity.adminId.display_name
              : "",
            groupName: message.activity.newGroupName,
          },
        ];
      case "left":
        return {
          ...message,
          _id: `${message._id}${messageIndex}`,
          messageType: "activity",
          activity_status: message.activity_status,
          variant:
            message.type === "userChatGroup"
              ? "UserLeaveGroup"
              : "UserLeaveChannel",
          name:
            message.activity.userId && message.activity.userId[0].display_name
              ? message.activity.userId[0].display_name
              : "",
        };
      case "added":
      case "addChannelMembers":
        return {
          ...message,
          _id: `${message._id}${messageIndex}`,
          messageType: "activity",
          activity_status: message.activity_status,
          variant:
            message.activity.type === "added"
              ? "UserJoinedGroup"
              : "UserJoinedChannel",
          name: message.activity.userId
            .map((user) => {
              return user.display_name
                ? user.display_name
                : "";
            })
            .join(","),
        };
      case "removed":
        return {
          ...message,
          _id: `${message._id}${messageIndex}`,
          messageType: "activity",
          activity_status: message.activity_status,
          variant: "UserRemovedFromGroup",
          userName: message.activity.userId
            .map((user) => {
              return user.display_name
                ? user.display_name
                : "";
            })
            .join(","),
          name: message.activity.adminId
            ? message.activity.adminId.display_name
            : "",
        };
      // -Please not Remove this commented code this code is future use when client revert to channel Activity
      // case "removedChannelMembers":
      //   return {
      //     ...message,
      //     _id: `${message._id}${messageIndex}`,
      //     messageType: "activity",
      //     activity_status: message.activity_status,
      //     variant: "UserRemovedFromChannel",
      //     userName: message.activity.userId
      //       .map((user) => {
      //         return user.display_name
      //           ? user.display_name.substring(0, user.display_name.indexOf(" "))
      //           : "";
      //       })
      //       .join(","),
      //     name: "Admin",
      //   };
      case "editedImage":
        return {
          ...message,
          _id: `${message._id}${messageIndex}`,
          messageType: "activity",
          activity_status: message.activity_status,
          variant: "ChangedGroupImage",
          name: message.activity.adminId
            ? message.activity.adminId.display_name
            : "",
        };
      // -Please not Remove this commented code this code is future use when client revert to channel Activity
      // case "editedChannelIcon":
      //   return {
      //     ...message,
      //     _id: `${message._id}${messageIndex}`,
      //     messageType: "activity",
      //     activity_status: message.activity_status,
      //     variant: "ChangedChanneImage",
      //     name: "Admin",
      //   };
      case "editedName":
        return {
          ...message,
          _id: `${message._id}${messageIndex}`,
          messageType: "activity",
          activity_status: message.activity_status,
          variant: "EditedGroupName",
          name: message.activity.adminId
            ? message.activity.adminId.display_name
            : "",
          oldName: message.activity.previousGroupName,
          newName: message.activity.newGroupName,
        };
      // -Please not Remove this commented code this code is future use when client revert to channel Activity
      // case "editedChannelName":
      //   return {
      //     ...message,
      //     _id: `${message._id}${messageIndex}`,
      //     messageType: "activity",
      //     activity_status: message.activity_status,
      //     variant: "EditedChannelName",
      //     name: "Admin",
      //     oldName: message.activity.previousGroupName,
      //     newName: message.activity.newGroupName,
      //   };
      default:
        return {
          ...message,
          _id: `${message._id}${messageIndex}`,
          messageType: "activity",
          activity_status: message.activity_status,
          variant: "GroupLimit",
          memberCount: 50,
        };
    }
  } else if (message?.message_type.length) {
    let messageTime = message.userTimeStamp
      ? moment(message.userTimeStamp).format("hh:mm A")
      : "";

    if (message?.quote_message_id && isQuoteMessage) {
      let taggedUserIdReplace = replaceIdWithNameUsingUnformattedList(
        message.message,
        message.taggedUserId,
        false
      );
      let taggedUserIdReplaceWithSPan = replaceIdWithNameUsingUnformattedList(
        message.message,
        message.taggedUserId,
        true
      );
      let taggedUserIdReplaceReply = replaceIdWithNameUsingUnformattedList(
        message?.quote_message_id?.message,
        message?.quote_message_id?.taggedUserId,
        false
      );
      let taggedUserIdReplaceWithSPanReply =
        replaceIdWithNameUsingUnformattedList(
          message?.quote_message_id?.message,
          message?.quote_message_id?.taggedUserId,
          true
        );

      return {
        ...message,
        _id: `${message._id}${messageIndex}`,
        messageType: "reply",
        // Main Components Prompts
        isHighlighted: false,
        hasName: true,
        hasAction: false,
        name: message.sender.display_name,
        message: taggedUserIdReplace,
        messageInArray: taggedUserIdReplaceWithSPan,
        isRead:
          message.sender.id === localStorage.getItem("userid")
            ? message.group_member.filter((member) => !member.readmsg).length >
              0
              ? false
              : true
            : true,
        hasBackdrop: false,
        isEdited: message.edited,
        time: messageTime,

        // Reaction
        hasCounter: false,
        hasReaction2: false,
        counter: 0,
        Avtarsrc: message.sender.image,
        AvatarType: message?.sender?.image?.length ? "photo" : "letter",
        AvtarLetter: message?.sender.display_name,
        //  Reply Message type
        receiverName: message.quote_message_id.sender?.display_name ?? "",
        srcOfReplayMessage:
          message.quote_message_id.images &&
            message.quote_message_id.images.length
            ? message.quote_message_id.images[0].url
            : message.quote_message_id.videos &&
              message.quote_message_id.videos.length
              ? message.quote_message_id.videos[0].thumbnail
              : "",
        messageToReply: taggedUserIdReplaceReply ?? "",
        messageToReplyArray: taggedUserIdReplaceWithSPanReply ?? "",
        altReply: "Image",
        MessageReplyType:
          message.quote_message_id.images &&
            message.quote_message_id.images.length
            ? "ImageReply"
            : message.quote_message_id.videos &&
              message.quote_message_id.videos.length
              ? "VideoReply"
              : "MessageReply",
        // create a common keys for reactions
        hasCounter:
          message.messageReactions &&
          message.messageReactions.length > 0 &&
          message.messageReactions.reduce((accumulator, currentValue) => {
            return accumulator + currentValue?.userIds.length;
          }, 0) > 0,
        hasReactions:
          message.messageReactions &&
          message.messageReactions.length > 0 &&
          message.messageReactions.reduce((accumulator, currentValue) => {
            return accumulator + currentValue?.userIds.length;
          }, 0) > 0,
        hasReaction2:
          message.messageReactions &&
          message.messageReactions.length > 0 &&
          message.messageReactions.reduce((accumulator, currentValue) => {
            return accumulator + currentValue?.userIds.length;
          }, 0) > 1,
        hasReaction3:
          message.messageReactions &&
          message.messageReactions.length > 0 &&
          message.messageReactions.reduce((accumulator, currentValue) => {
            return accumulator + currentValue?.userIds.length;
          }, 0) > 2,
        hasReaction4:
          message.messageReactions &&
          message.messageReactions.length > 0 &&
          message.messageReactions.reduce((accumulator, currentValue) => {
            return accumulator + currentValue?.userIds.length;
          }, 0) > 3,
        counter: message.messageReactions
          ? message.messageReactions.reduce((accumulator, currentValue) => {
            return accumulator + currentValue?.userIds.length;
          }, 0)
          : 0,
        emoji1:
          message.messageReactions &&
            message.messageReactions[0]?.userIds.length
            ? message.messageReactions[0]?.emojiId
            : "",
        emoji2:
          message.messageReactions &&
            message.messageReactions[1]?.userIds.length
            ? message.messageReactions[1]?.emojiId
            : "",
        emoji3:
          message.messageReactions &&
            message.messageReactions[2]?.userIds.length
            ? message.messageReactions[2]?.emojiId
            : "",
        emoji4:
          message.messageReactions &&
            message.messageReactions[3]?.userIds.length
            ? message.messageReactions[3]?.emojiId
            : "",
      };
    }
    if (message?.quote_message_id && !isQuoteMessage) {
      let splitType =
        message.message_type.indexOf("url") !== -1
          ? message.message_type.split(",").filter((type) => type !== "text")
          : message.message_type.split(",");
      splitType =
        splitType.includes("text") || splitType.includes("url")
          ? splitType
          : ["text", ...splitType];
      if (splitType.length > 0) {
        return splitType.map((type, index) => {
          if (type === "text" || type === "url") {
            return formatMessage(
              {
                ...message,
                message_type: type,
                quote_message_id: index === 0 ? message.quote_message_id : null,
              },
              index,
              isLastMessage && index === splitType.length - 1,
              index === 0
            );
          } else {
            return formatMessage(
              { ...message, message_type: type, quote_message_id: null },
              index,
              isLastMessage && index === splitType.length - 1,
              false
            );
          }
        });
      }
    }
    let commonReactionProperties =
      messageIndex === 0
        ? {
          hasCounter:
            message.messageReactions &&
            message.messageReactions.length > 0 &&
            message.messageReactions.reduce((accumulator, currentValue) => {
              return accumulator + currentValue?.userIds.length;
            }, 0) > 0,
          hasReactions:
            message.messageReactions &&
            message.messageReactions.length > 0 &&
            message.messageReactions.reduce((accumulator, currentValue) => {
              return accumulator + currentValue?.userIds.length;
            }, 0) > 0,
          hasReaction2:
            message.messageReactions &&
            message.messageReactions.length > 0 &&
            message.messageReactions.reduce((accumulator, currentValue) => {
              return accumulator + currentValue?.userIds.length;
            }, 0) > 1,
          hasReaction3:
            message.messageReactions &&
            message.messageReactions.length > 0 &&
            message.messageReactions.reduce((accumulator, currentValue) => {
              return accumulator + currentValue?.userIds.length;
            }, 0) > 2,
          hasReaction4:
            message.messageReactions &&
            message.messageReactions.length > 0 &&
            message.messageReactions.reduce((accumulator, currentValue) => {
              return accumulator + currentValue?.userIds.length;
            }, 0) > 3,
          counter: message.messageReactions
            ? message.messageReactions.reduce((accumulator, currentValue) => {
              return accumulator + currentValue?.userIds.length;
            }, 0)
            : 0,
          emoji1:
            message.messageReactions &&
              message.messageReactions[0]?.userIds.length
              ? message.messageReactions[0]?.emojiId
              : "",
          emoji2:
            message.messageReactions &&
              message.messageReactions[1]?.userIds.length
              ? message.messageReactions[1]?.emojiId
              : "",
          emoji3:
            message.messageReactions &&
              message.messageReactions[2]?.userIds.length
              ? message.messageReactions[2]?.emojiId
              : "",
          emoji4:
            message.messageReactions &&
              message.messageReactions[3]?.userIds.length
              ? message.messageReactions[3]?.emojiId
              : "",
        }
        : {
          hasCounter: false,
          hasReactions: false,
          hasReaction2: false,
          hasReaction3: false,
          hasReaction4: false,
          counter: false,
          emoji1: false,
          emoji2: false,
          emoji3: false,
          emoji4: false,
        };

    let commonProperties = {
      ...message,
      _id: `${message._id}${messageIndex}`,
      activity_status: message.activity_status,
      MetaData: message.MetaData,
      isHighlighted: false,
      hasAction: false,
      isRead:
        message.sender.id === localStorage.getItem("userid") &&
        (!message.group_member ||
          message.group_member.every((member) => member.readmsg)),
      hasBackdrop: false,
      isEdited: message.edited,
      time: messageTime,
      Avtarsrc: message.sender.image,
      AvatarType: message?.sender?.image?.length ? "photo" : "letter",
      AvtarLetter: message?.sender.display_name,
      ...commonReactionProperties,
    };
    let taggedUserIdReplace = replaceIdWithNameUsingUnformattedList(
      message.message,
      message.taggedUserId,
      false
    );
    let taggedUserIdReplaceWithSPan = replaceIdWithNameUsingUnformattedList(
      message.message,
      message.taggedUserId,
      true
    );

    switch (message.message_type) {
      case "text":
        return {
          ...commonProperties,
          messageType: checkEmojiMessage(message?.message, 5)
            ? "emoji"
            : "text",
          hasName: true,
          name: message.sender.display_name,
          message: taggedUserIdReplace,
          messageInArray: taggedUserIdReplaceWithSPan,
        };
      case "image":
        return {
          ...commonProperties,
          messageType: "image",
          hasTime: true,
          src: message.images
            ? message.images.map((img) => {
              return {
                img: img.url,
                id: img.id,
                aspectRatio: img.aspectRatio,
              };
            })
            : [],
          count: message.images ? Math.max(0, message.images.length - 3) : 0,
          id: message.images ? message.images.map((img) => img.id) : [],
          name: message.sender.display_name,
        };
      case "video":
        return {
          ...commonProperties,
          messageType: "video",
          hasTime: true,
          src: message.videos
            ? message.videos.map((video) => {
              return {
                video: video.thumbnail,
                id: video.id,
                videoUrl: video.url,
              };
            })
            : [],
          count: message.videos ? Math.max(0, message.videos.length - 3) : 0,
          videoType: message.videos
            ? message.videos.map((video) =>
              video.aspectRatio > 1
                ? "Horizontal"
                : video.aspectRatio === 1
                  ? "Squre"
                  : "Vertical"
            )
            : "",
          hasReaction2: false,
          id: message.videos ? message.videos.map((video) => video.id) : [],
          name: message.sender.display_name,
        };
      case "document":
        return message.documents.map((document) => {
          // Handling documents
          return {
            ...commonProperties,
            messageType: "file",
            hasTime: true,
            files: {
              fileName: document?.url
                ?.split("?")[0]
                .split("-GRPCHATUNIQUE-")
                .pop(),
              size: formatBytes(document.size),
              id: document.id,
              url: document?.url,
              thumbnail: document.thumbnail,
            },
            id: document.id,
          };
        });

      case "voice":
        return {
          ...commonProperties,
          messageType: "voice",
          hasName: true,
          name: message.sender.display_name,
          voiceSrc: message.voiceNotes ? message.voiceNotes[0] : "",
        };

      case "url":
        return {
          ...commonProperties,
          messageType: "link",
          metaImageSrc: "",
          metaTitle: "",
          metaLink: "",
          metaDescription: "",
          metaShortDescription: "",
          hasName: true,
          name: message.sender.display_name,
          message: taggedUserIdReplace,
          messageInArray: taggedUserIdReplaceWithSPan,
        };
      case "processing":
        return {
          ...commonProperties,
          messageType: "processing",
        };
      default:
        let splitType =
          message.message_type.indexOf("url") !== -1
            ? message.message_type.split(",").filter((type) => type !== "text")
            : message.message_type.split(",");
        return splitType.map((type, index) =>
          formatMessage(
            { ...message, message_type: type },
            index,
            isLastMessage && index === splitType.length - 1
          )
        );
    }

  }
};

export const groupChatData = (allMessages) => {
  let originalMessageArray = allMessages ?? [];
  let groupedMessagesArray = [];
  let messages = [];
  for (let index = 0; index < originalMessageArray.length; index++) {
    let formattedMessageDataResponse = formatMessage(
      originalMessageArray[index],
      0,
      index === originalMessageArray.length - 1
    );
    let formattedMessageData = Array.isArray(formattedMessageDataResponse)
      ? [].concat(
        ...formattedMessageDataResponse.map((item) =>
          Array.isArray(item) ? item : [item]
        )
      )
      : formattedMessageDataResponse;
    let currentMessageDate = moment(
      originalMessageArray[index].userTimeStamp
    ).format("MMMM D, YYYY");
    let nextMessageDate =
      index < originalMessageArray.length - 1
        ? moment(originalMessageArray[index + 1].userTimeStamp).format(
          "MMMM D, YYYY"
        )
        : "";
    let dateFormat =
      currentMessageDate === moment().format("MMMM D, YYYY")
        ? "Today"
        : moment().diff(currentMessageDate, "days") === 1
          ? "Yesterday"
          : currentMessageDate;
    let prevDate =
      index > 0
        ? moment(
          originalMessageArray[index - 1].userTimeStamp,
          "YYYY-MM-DDTHH:mm:ss.SSSSSS[Z]"
        )
        : "";
    let currentDate = moment(
      originalMessageArray[index].userTimeStamp,
      "YYYY-MM-DDTHH:mm:ss.SSSSSS[Z]"
    );
    let nextDate =
      index !== originalMessageArray.length - 1
        ? moment(
          originalMessageArray[index + 1].userTimeStamp,
          "YYYY-MM-DDTHH:mm:ss.SSSSSS[Z]"
        )
        : "";
    let timeDifferenceGreaterThenFive =
      index === 0
        ? moment(currentDate).diff(nextDate, "days") > 0 ||
          moment(currentDate).diff(nextDate, "minutes") > 5
          ? true
          : false
        : index === originalMessageArray.length - 1
          ? moment(prevDate).diff(currentDate, "days") > 0 ||
            moment(prevDate).diff(currentDate, "minutes") > 5
            ? true
            : false
          : moment(prevDate).diff(currentDate, "days") > 0 ||
            moment(prevDate).diff(currentDate, "minutes") > 5
            ? true
            : false;
    if (index === 0) {
      messages = Array.isArray(formattedMessageData)
        ? [...formattedMessageData]
        : [formattedMessageData];
    } else if (
      index === originalMessageArray.length - 1 &&
      messages.length &&
      messages.slice(-1)[0].activity_status ===
      originalMessageArray[index].activity_status
    ) {
      if (
        messages.slice(-1)[0].activity_status ||
        (messages.slice(-1)[0].sender &&
          originalMessageArray[index].sender &&
          messages.slice(-1)[0].sender.id ===
          originalMessageArray[index].sender.id &&
          !timeDifferenceGreaterThenFive)
      ) {
        messages = Array.isArray(formattedMessageData)
          ? [...messages, ...formattedMessageData]
          : [...messages, formattedMessageData];

        if (messages.slice(-1)[0].activity_status) {
          // if (messages.slice(-1)[0].type !== "chatChannel") {
          messages.push({
            messageType: "activity",
            activity_status: true,
            variant: "Date",
            date: dateFormat,
          });
          // }
          groupedMessagesArray.push({
            messageType: originalMessageArray[index].activity_status
              ? "activity"
              : "messages",
            data: messages,
            isOutgoing: false,
          });
          messages = [];
        } else {
          groupedMessagesArray.push({
            messageType: originalMessageArray[index].activity_status
              ? "activity"
              : "messages",
            data: messages,
            isOutgoing:
              messages.slice(-1)[0].sender &&
                messages.slice(-1)[0].sender.id === localStorage.getItem("userid")
                ? true
                : false,
          });

          messages = [];
          // if (originalMessageArray[index].type !== "chatChannel") {
          groupedMessagesArray.push({
            messageType: "activity",
            data: [
              {
                messageType: "activity",
                activity_status: true,
                variant: "Date",
                date: dateFormat,
              },
            ],
            isOutgoing: false,
          });
          // }
        }
      } else {
        groupedMessagesArray.push({
          messageType: messages.slice(-1)[0].activity_status
            ? "activity"
            : "messages",
          data: messages,
          isOutgoing:
            messages.slice(-1)[0].sender &&
              messages.slice(-1)[0].sender.id === localStorage.getItem("userid")
              ? true
              : false,
        });
        messages = [];
        messages = Array.isArray(formattedMessageData)
          ? [...messages, ...formattedMessageData]
          : [...messages, formattedMessageData];
        groupedMessagesArray.push({
          messageType: originalMessageArray[index].activity_status
            ? "activity"
            : "messages",
          data: messages,
          isOutgoing:
            originalMessageArray[index].sender &&
              originalMessageArray[index].sender.id ===
              localStorage.getItem("userid")
              ? true
              : false,
        });
        // if (originalMessageArray[index].type !== "chatChannel") {
        messages = [];
        groupedMessagesArray.push({
          messageType: "activity",
          data: [
            {
              messageType: "activity",
              activity_status: true,
              variant: "Date",
              date: dateFormat,
            },
          ],
          isOutgoing: false,
        });
        // }
      }
    } else if (
      index === originalMessageArray.length - 1 &&
      messages.length &&
      messages.slice(-1)[0].activity_status ===
      originalMessageArray[index].activity_status
    ) {
      groupedMessagesArray.push({
        messageType: messages.slice(-1)[0].activity_status
          ? "activity"
          : "messages",
        data: messages,
        isOutgoing:
          messages.slice(-1)[0].sender &&
            messages.slice(-1)[0].sender.id === localStorage.getItem("userid")
            ? true
            : false,
      });

      messages = [];
      messages = Array.isArray(formattedMessageData)
        ? [...messages, ...formattedMessageData]
        : [...messages, formattedMessageData];

      if (originalMessageArray[index].activity_status) {
        // if (originalMessageArray[index].type !== "chatChannel") {
        messages.push({
          messageType: "activity",
          activity_status: true,
          variant: "Date",
          date: dateFormat,
        });
        // }
        groupedMessagesArray.push({
          messageType: originalMessageArray[index].activity_status
            ? "activity"
            : "messages",
          data: messages,
          isOutgoing:
            originalMessageArray[index].sender &&
              originalMessageArray[index].sender.id ===
              localStorage.getItem("userid")
              ? true
              : false,
        });
        messages = [];
      } else {
        // if (originalMessageArray[index].type !== "chatChannel") {
        groupedMessagesArray.push({
          messageType: "activity",
          data: [
            {
              messageType: "activity",
              activity_status: true,
              variant: "Date",
              date: dateFormat,
            },
          ],
          isOutgoing: false,
        });
        // }
        messages = [];
      }
    } else if (
      messages?.length &&
      messages?.slice(-1)[0]?.activity_status ===
      originalMessageArray[index].activity_status
    ) {
      if (
        messages.slice(-1)[0]?.activity_status ||
        (messages.slice(-1)[0].sender &&
          originalMessageArray[index].sender &&
          messages.slice(-1)[0].sender.id ===
          originalMessageArray[index].sender.id &&
          !timeDifferenceGreaterThenFive)
      ) {
        messages = Array.isArray(formattedMessageData)
          ? [...messages, ...formattedMessageData]
          : [...messages, formattedMessageData];
      } else {
        groupedMessagesArray.push({
          messageType: messages.slice(-1)[0].activity_status
            ? "activity"
            : "messages",
          data: messages,
          isOutgoing:
            messages.slice(-1)[0].sender &&
              messages.slice(-1)[0].sender.id === localStorage.getItem("userid")
              ? true
              : false,
        });
        messages = [];
        messages = Array.isArray(formattedMessageData)
          ? [...messages, ...formattedMessageData]
          : [...messages, formattedMessageData];
      }
    } else if (
      messages.length &&
      messages.slice(-1)[0].activity_status !==
      originalMessageArray[index].activity_status
    ) {
      groupedMessagesArray.push({
        messageType: messages.slice(-1)[0].activity_status
          ? "activity"
          : "messages",
        data: messages,
        isOutgoing:
          messages.slice(-1)[0].sender &&
            messages.slice(-1)[0].sender.id === localStorage.getItem("userid")
            ? true
            : false,
      });
      messages = [];
      messages = Array.isArray(formattedMessageData)
        ? [...messages, ...formattedMessageData]
        : [...messages, formattedMessageData];
    }
    if (nextMessageDate !== currentMessageDate) {
      if (messages && messages.length) {
        if (messages?.slice(-1)[0]?.activity_status) {
          // if (messages?.slice(-1)[0]?.type !== "chatChannel") {
          messages.push({
            messageType: "activity",
            activity_status: true,
            variant: "Date",
            date: dateFormat,
          });
          // }
        } else {
          groupedMessagesArray.push({
            messageType: messages?.slice(-1)[0]?.activity_status
              ? "activity"
              : "messages",
            data: messages,
            isOutgoing:
              messages?.slice(-1)[0]?.sender &&
                messages?.slice(-1)[0]?.sender.id ===
                localStorage.getItem("userid")
                ? true
                : false,
          });
          messages = [];
          messages.push({
            messageType: "activity",
            activity_status: true,
            variant: "Date",
            date: dateFormat,
          });
        }
      }
    }
    if (index === originalMessageArray.length - 1 && messages.length) {
      groupedMessagesArray.push({
        messageType: messages.slice(-1)[0].activity_status
          ? "activity"
          : "messages",
        data: messages,
        isOutgoing:
          messages.slice(-1)[0].sender &&
            messages.slice(-1)[0].sender.id === localStorage.getItem("userid")
            ? true
            : false,
      });
    }
  }
  return groupedMessagesArray;
};

export const formatActivityMessagesOnly = (allMessages) => {
  let activityData = [];
  allMessages.map((messageData, index) => {
    let message = {
      ...messageData,
      isLastMessage: index === allMessages.length - 1,
    };
    switch (message.activity.type) {
      case "createdChannel":
        activityData = [
          ...activityData,
          {
            ...message,
            _id: `${message._id}0`,
            messageType: "activity",
            activity_status: message.activity_status,
            variant: "ChannelCreated",
            name: "Admin",
            channelName: message.activity.newGroupName,
          },
        ];
        break;
      case "created":
        activityData = [
          ...activityData,
          {
            ...message,
            _id: `${message._id}0`,
            messageType: "activity",
            activity_status: message.activity_status,
            variant: "GroupLimit",
            memberCount: 50,
          },
          {
            ...message,
            _id: `${message._id}1`,
            messageType: "activity",
            activity_status: message.activity_status,
            variant: "GroupCreated",
            name: message.activity.adminId
              ? message.activity.adminId.display_name
              : "",
            groupName: message.activity.newGroupName,
          },
        ];
        break;
      case "left":
        activityData = [
          ...activityData,
          {
            ...message,
            _id: `${message._id}0`,
            messageType: "activity",
            activity_status: message.activity_status,
            variant:
              message.type === "userChatGroup"
                ? "UserLeaveGroup"
                : "UserLeaveChannel",
            name:
              message.activity.userId && message.activity.userId[0].display_name
                ? message.activity.userId[0].display_name.substring(
                  0,
                  message.activity.userId[0].display_name.indexOf(" ")
                )
                : "",
          },
        ];
        break;
      case "added":
      case "addChannelMembers":
        activityData = [
          ...activityData,
          {
            ...message,
            _id: `${message._id}0`,
            messageType: "activity",
            activity_status: message.activity_status,
            variant:
              message.activity.type === "added"
                ? "UserJoinedGroup"
                : "UserJoinedChannel",
            name: message.activity.userId
              .map((user) => {
                return user.display_name
                  ? user.display_name.substring(0, user.display_name.indexOf(" "))
                  : "";
              })
              .join(","),
          },
        ];
        break;
      case "removed":
        activityData = [
          ...activityData,
          {
            ...message,
            _id: `${message._id}0`,
            messageType: "activity",
            activity_status: message.activity_status,
            variant: "UserRemovedFromGroup",
            userName: message.activity.userId
              .map((user) => {
                return user.display_name
                  ? user.display_name.substring(0, user.display_name.indexOf(" "))
                  : "";
              })
              .join(","),
            name: message.activity.adminId
              ? message.activity.adminId.display_name
              : "",
          },
        ];
        break;
      case "removedChannelMembers":
        activityData = [
          ...activityData,
          {
            ...message,
            _id: `${message._id}0`,
            messageType: "activity",
            activity_status: message.activity_status,
            variant: "UserRemovedFromChannel",
            userName: message.activity.userId
              .map((user) => {
                return user.display_name
                  ? user.display_name.substring(0, user.display_name.indexOf(" "))
                  : "";
              })
              .join(","),
            name: "Admin",
          },
        ];
        break;
      case "editedImage":
        activityData = [
          ...activityData,
          {
            ...message,
            _id: `${message._id}0`,
            messageType: "activity",
            activity_status: message.activity_status,
            variant: "ChangedGroupImage",
            name: message.activity.adminId
              ? message.activity.adminId.display_name
              : "",
          },
        ];
        break;
      case "editedChannelIcon":
        activityData = [
          ...activityData,
          {
            ...message,
            _id: `${message._id}0`,
            messageType: "activity",
            activity_status: message.activity_status,
            variant: "ChangedChanneImage",
            name: "Admin",
          },
        ];
        break;
      case "editedName":
        activityData = [
          ...activityData,
          {
            ...message,
            _id: `${message._id}0`,
            messageType: "activity",
            activity_status: message.activity_status,
            variant: "EditedGroupName",
            name: message.activity.adminId
              ? message.activity.adminId.display_name
              : "",
            oldName: message.activity.previousGroupName,
            newName: message.activity.newGroupName,
          },
        ];
        break;
      case "editedChannelName":
        activityData = [
          ...activityData,
          {
            ...message,
            _id: `${message._id}0`,
            messageType: "activity",
            activity_status: message.activity_status,
            variant: "EditedChannelName",
            name: "Admin",
            oldName: message.activity.previousGroupName,
            newName: message.activity.newGroupName,
          },
        ];
        break;
      default:
        activityData = [
          ...activityData,
          {
            ...message,
            _id: `${message._id}0`,
            messageType: "activity",
            activity_status: message.activity_status,
            variant: "GroupLimit",
            memberCount: 50,
          },
        ];
        break;
    }
  });
  return [
    {
      messageType: "activity",
      data: activityData,
    },
  ];
};
